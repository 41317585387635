import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import { COMPANY_INSURANCE_STEP1, OVERSEAS_TRAVEL_INSURANCE_STEP4 } from '../../utils/routers';
import useOverseasTravelFunnel from '../../hooks/useOverseasTravelFunnel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import { CompanionType } from '../../types/insurance/overseas-travle';
import {
  EMAIL_SCHEMA,
  ENGLISH_NAME_SCHEMA,
  NAME_SCHEMA,
  SSN_NUMBER_SCHEMA,
} from '../../constants/validationSchemas';
import useMaskedLastSsnNumber from '../../hooks/useMaskedLastSsnNumber';
import { setFormikTouched, validFormik } from '../../utils/formik';
import Cautioninfo from '../../components/Cautioninfo';
import useBottomSheet from '../../hooks/useBottomSheet';

const overseasTravelInsuranceStep3Schema = yup.object({
  claimantName: NAME_SCHEMA('피보험자'),
  englishName: ENGLISH_NAME_SCHEMA,
  claimantSsnNumber: SSN_NUMBER_SCHEMA,
  email: EMAIL_SCHEMA,
});

function OverseasTravelInsuranceStep3() {
  const { goTo } = useNavigateTo();
  const { step3Values, setStep3Values, step2Values, setStep2Values } = useOverseasTravelFunnel();
  const ref = useRef<HTMLInputElement>(null);
  const formik = useFormik({
    initialValues: step3Values || {},
    validationSchema: overseasTravelInsuranceStep3Schema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  const [isDirty, setIsDirty] = useState(false);
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik,
    originName: 'claimantSsnNumber',
  });

  const [companions, setCompanions] = useState(step2Values?.companion || []);

  const isDisabled = useMemo(
    () => !formik.isValid || companions.some(comp => !comp.isValid),
    [formik.isValid, companions]
  );

  const handleNext = async () => {
    setIsDirty(true);
    setFormikTouched(formik);
    const isValid = (await validFormik(formik)) && companions.every(comp => comp.isValid);
    if (!isValid) return;
    if (!isDisabled) {
      toggleBottomSheet(true)
      // goTo(OVERSEAS_TRAVEL_INSURANCE_STEP4);
    }
  };

  useEffect(() => {
    setStep2Values(prev => ({
      ...prev,
      companion: companions,
    }));
  }, [companions]);

  useEffect(() => {
    setStep3Values(formik.values);
  }, [formik.values]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='보험가입' />

      <div className='container'>
        <div className={'guide gray_bortop pb_28'}>
          <div className='fs_16 fw_600 mt_28'>1. 보험가입자(피보험자)</div>
          <FormControl
            label='피보험자 이름'
            required
            hasError={!!formik.errors.claimantName && formik.touched.claimantName}
          >
            <FormInput
              type='text'
              placeholder='이름을 입력해 주세요'
              value={formik.values.claimantName}
              name='claimantName'
              onChange={formik.handleChange}
              formik={formik}
            />
          </FormControl>
          <FormControl
            label='영문 이름'
            required
            hasError={!!formik.errors.englishName && formik.touched.englishName}
          >
            <FormInput type='text' placeholder='이름 입력' formik={formik} name='englishName' />
          </FormControl>

          <div className='inp_wrap mt_28'>
            <label className='label'>
              피보험자 주민번호 <span className='col_red'>*</span>
            </label>
            <div className='dis_flex gap8'>
              <div className='inp flex1'>
                <input
                  type='number'
                  placeholder='생년월일 6자리'
                  value={formik.values.claimantSsnNumberFirst}
                  name='claimantSsnNumberFirst'
                  onChange={onChangeSsnNumber}
                  style={{
                    borderColor:
                      formik.errors.claimantSsnNumber && formik.touched.claimantSsnNumber
                        ? 'red'
                        : '',
                  }}
                />
              </div>
              <span>-</span>
              <div className='inp flex1'>
                <input type='hidden' ref={ref} value={formik.values.claimantSsnNumberLast} />
                <input
                  type='text'
                  placeholder='뒷 번호 7자리'
                  value={maskedLastSsnNumber}
                  name='claimantSsnNumberLast'
                  onChange={onChangeSsnNumber}
                  style={{
                    borderColor:
                      formik.errors.claimantSsnNumber && formik.touched.claimantSsnNumber
                        ? 'red'
                        : '',
                  }}
                />
              </div>
            </div>
            {formik.errors.claimantSsnNumber && formik.touched.claimantSsnNumber && (
              <FormErrorMessage>{formik.errors.claimantSsnNumber}</FormErrorMessage>
            )}
          </div>
        </div>
        {(companions?.length || 0) > 0 && (
          <div className='guide gray_bortop'>
            {companions?.map(comp => (
              <CompanionForm
                key={comp.key}
                companion={comp}
                setCompanions={setCompanions}
                isDirty={isDirty}
              />
            ))}
          </div>
        )}

        <div className='guide'>
          <div className='mt_24'>
            <p className='fs_16 fw_600'>가입증빙서류 받으실 이메일 주소를 입력해주세요.</p>
            <p className='mt_4 fs_14 col_gray3'>가입확인서(증명서), 약관 전송 시 사용됩니다.</p>
          </div>

          <FormControl
            label='이메일'
            required
            hasError={!!formik.errors.email && formik.touched.email}
          >
            <FormInput
              type='email'
              placeholder='이메일을 입력해 주세요'
              formik={formik}
              name='email'
            />
          </FormControl>
        </div>
      </div>

      <div className='foot_container'>
        <div className='foot_btn'>
          {/* TODO: className > disabled 활성화/비활성화 */}
          <button
            className={`btn blue`}
            onClick={handleNext}
            // disabled={isDisabled}
          >
            다음
          </button>
        </div>
      </div>
      <Cautioninfo bottomSheetRef={bottomSheetRef} toggleBottomSheet={toggleBottomSheet} name={OVERSEAS_TRAVEL_INSURANCE_STEP4}/>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default OverseasTravelInsuranceStep3;

const companionSchema = yup.object({
  companionName: NAME_SCHEMA('동반자'),
  companionEnglishName: ENGLISH_NAME_SCHEMA,
  companionSsnNumber: SSN_NUMBER_SCHEMA,
});

export const CompanionForm = ({
  companion,
  setCompanions,
  isDirty,
}: {
  companion: CompanionType;
  setCompanions: React.Dispatch<React.SetStateAction<CompanionType[]>>;
  isDirty: boolean;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const formik = useFormik({
    initialValues: companion,
    validationSchema: companionSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const { maskedLastSsnNumber, onChangeSsnNumber } = useMaskedLastSsnNumber({
    ref,
    formik,
    originName: 'companionSsnNumber',
  });

  const isDirtyMemo = useMemo(() => isDirty, [isDirty]);
  useEffect(() => {
    const valid = async () => {
      setFormikTouched(formik);
      const isValid = await validFormik(formik);
      setCompanions(prev =>
        prev.map(comp => (comp.key === companion.key ? { ...formik.values, isValid } : comp))
      );
    };
    if (isDirtyMemo) {
      valid();
    }
  }, [isDirtyMemo]);

  // key 값을 가지고 해당 객체 수정
  useEffect(() => {
    const isValid = formik.isValid;

    setCompanions(prev =>
      prev.map(comp => (comp.key === companion.key ? { ...formik.values, isValid } : comp))
    );
  }, [formik.values, formik.isValid, formik.touched, setCompanions, companion.key]);

  return (
    <>
      <div className='fs_16 fw_600 mt_28'>2. 동반자 {companion.key}</div>
      <FormControl
        label='동반자 이름'
        required
        hasError={!!formik.errors.companionName && formik.touched.companionName}
      >
        <FormInput
          type='text'
          placeholder='이름을 입력해 주세요'
          formik={formik}
          name='companionName'
        />
      </FormControl>

      <FormControl
        label='영문 이름'
        required
        hasError={!!formik.errors.companionEnglishName && formik.touched.companionEnglishName}
      >
        <FormInput
          type='text'
          placeholder='이름 입력'
          formik={formik}
          name='companionEnglishName'
        />
        <p className='col_gray3 fs_14'>여권상의 영문명과 동일하게 작성해 주세요.</p>
      </FormControl>

      <FormControl
        label='동반자 주민번호'
        required
        hasError={!!formik.errors.companionSsnNumber && formik.touched.companionSsnNumber}
      >
        <div className='dis_flex gap8'>
          <div className='inp flex1'>
            <input
              type='number'
              placeholder='생년월일 6자리'
              value={formik.values.companionSsnNumberFirst}
              name='companionSsnNumberFirst'
              onChange={onChangeSsnNumber}
            />
          </div>
          <span>-</span>
          <div className='inp flex1'>
            <input type='hidden' ref={ref} value={formik.values.companionSsnNumberLast} />
            <input
              type='text'
              placeholder='뒷 번호 7자리'
              value={maskedLastSsnNumber}
              name='companionSsnNumberLast'
              onChange={onChangeSsnNumber}
            />
          </div>
        </div>
        {formik.errors.companionSsnNumber && (
          <FormErrorMessage>{formik.errors.companionSsnNumber}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};
