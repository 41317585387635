import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

function CreditInquiry() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='개인(신용)정보 조회에 관한 동의' terms={true} />

        {/* <!-- container --> */}
        <div style={styles.container}>
          <h1 style={styles.sectionTitle}>[필수] 보험금청구를 위한 상세동의서</h1>

          <p style={styles.note}>
            귀하는 개인(신용)정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의를 철회할 수 있습니다.
            다만, 본 동의는 ‘보험금 청구’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무 수행이 불가능 합니다.
          </p>

          {/* 수집·이용에 관한 사항 */}
          <section>
            <h2 style={styles.sectionTitle}>1. 조회에 관한 사항</h2>

            <h3 style={styles.sectionTitle}>• 조회 대상 기관</h3>
            <ul style={styles.list}>
              <li>종합신용정보집중기관, 손해보험협회, 보험요율산출기관, 국토교통부</li>
            </ul>

            <h3 style={styles.sectionTitle}>• 조회목적</h3>
            <ul style={styles.list}>
              <li>
                - 종합신용정보집중기관: 보험사고보험사기조사 및 보험금지급 심사, 교통사고 처리내역 발급 간소화 서비스
              </li>
              <li>
                - 보험요율산출기관,국토교통부: 보험사고 보험사기 조사 및 보험금지급 심사
              </li>
              <li>- 손해보험협회: 보험금 청구서류 접수대행 서비스</li>
            </ul>
            <p style={styles.note}>
              • 조회 동의의 효력기간: 해당 보험거래 종료일까지 동의의 효력이 지속됩니다
            </p>

            <h3 style={styles.sectionTitle}>• 조회항목</h3>
            <ul style={styles.list}>
              <li>
                고유식별정보 : 운전면허번호
              </li>
              <li>민감정보: - 피보험자의 질병,상해에 관한 정보(진료기록,상병명 등)</li>
              <li>- 교통법규 위반정보, 교통사고조사기록(당사의 요청에 따라 보험요율산출기관이 경찰청으로부터 제공받은 정보)</li>
              <li>개인(신용)정보: -일반개인정보: 성명, 면허의 효력에 관한 정보</li>
              <li>-신용거래정보: 보험계약정보(상품종류,기간,보험가입금액 등), 보험금정보(보험 금지급사유,지급금액 등)</li>
            </ul>
            <p style={styles.note}>
              ※ 만14세 미만의 경우 법정대리인(친권자 또는 후견인)이 작성 후 서명을 하시고, 만14세 이상의 경우 미성년 본인 직접 동의 또는 법정대리인(친권자 또는 후견인) 대리동의 후 서명하시기
              바랍니다.
            </p>
          </section>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

const styles = {
  container: {
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '10px',
    marginBottom: '10px',
  },
  list: {
    // paddingLeft: '20px',
    marginBottom: '16px',
    lineHeight: '1.6',
    fontSize: '14px',
    color: '#666',
  },
  note: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '16px',
  },
};

export default CreditInquiry;
