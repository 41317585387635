import {
  CompanyInsuranceValuesType,
  CompanyInsuranceCodeValuesType,
  CompanyInsuranceStep1ValuesType,
  CompanyInsuranceStep2ValuesType,
  CompanyInsuranceStep3ValuesType,
} from '../types/insurance/company-insurance';
import { useLocalStorage } from './useLocalStorage';

export const COMPANY_INSURANCE_STORAGE_KEYS = {
  STEP_VALUES: 'companyInsurance-insurance-values',
  STEPCODE_VALUES: 'companyInsurance-insurance-code-values',
  STEP1_VALUES: 'companyInsurance-insurance-step1-values',
  STEP2_VALUES: 'companyInsurance-insurance-step2-values',
  STEP3_VALUES: 'companyInsurance-insurance-step3-values',
  CURRENT_STEP: 'companyInsurance-insurance-current-step',
};

const useCompanyInsuranceFunnel = () => {
  const [codeValues, setCodeValues] = useLocalStorage<CompanyInsuranceCodeValuesType>(
    COMPANY_INSURANCE_STORAGE_KEYS.STEPCODE_VALUES,
    {
      accessCode: '',
    }
  );

  const [step1Values, setStep1Values] = useLocalStorage<CompanyInsuranceStep1ValuesType>(
    COMPANY_INSURANCE_STORAGE_KEYS.STEP1_VALUES,
    {
      reName: '',
      rePhone: '',
      email: '',
      accidentDay: '00:00',
      workplace: '',
      accidentLocation: '',
      accidentDetails: '',
    }
  );

  const [step2Values, setStep2Values] = useLocalStorage<CompanyInsuranceStep2ValuesType>(
    COMPANY_INSURANCE_STORAGE_KEYS.STEP2_VALUES,
    {
      damageType: 0,
      damageSummary: '',
      expectedDamageAmount: '',
      etcNote: '',
      address: '',
      addressDetail: '',
      birth: '',
      victimPhone: '',
      victimName: '',
      deputy: '',
      injuries: '',
      relationship: '',
      victimNote: '',
    }
  );

  const [step3Values, setStep3Values] = useLocalStorage<CompanyInsuranceStep3ValuesType>(
    COMPANY_INSURANCE_STORAGE_KEYS.STEP3_VALUES,
    {
      collectionStatus: false,
      provisionStatus: false,
      inquiryStatus: false,
      claimantName: '',
      claimantSignaturePreview: '',
    }
  );

  // const [step4Values, setStep4Values] = useLocalStorage<{
  //   isAgree: boolean;
  //   agreeValues: { [key: string]: boolean | undefined };
  // }>(COMPANY_INSURANCE_STORAGE_KEYS.STEP4_VALUES, {
  //   isAgree: false,
  //   agreeValues: {
  //     check0: undefined,
  //     check1: undefined,
  //     check2: undefined,
  //     check3: undefined,
  //   },
  // });

  // 현재 스텝 저장
  const [currentStep, setCurrentStep] = useLocalStorage(
    COMPANY_INSURANCE_STORAGE_KEYS.CURRENT_STEP,
    1
  );

  // 폼 초기화
  const clearForm = () => {
    Object.values(COMPANY_INSURANCE_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
    setCurrentStep(1);
  };

  const initialValues = {
    codeValues,
    step1Values,
    step2Values,
    step3Values,
  };

  const finalValues = {
    ...codeValues,
    ...step1Values,
    ...step2Values,
    ...step3Values,
  } as CompanyInsuranceValuesType;

  return {
    codeValues,
    step1Values,
    step2Values,
    step3Values,
    // step4Values,
    setCodeValues,
    setStep1Values,
    setStep2Values,
    setStep3Values,
    // setStep4Values,
    currentStep,
    setCurrentStep,
    clearForm,
    initialValues,
    finalValues,
  };
};

export default useCompanyInsuranceFunnel;
