import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { HOME, PET_GOODANDGOOD_PRODUCT, PET_HIPET_PRODUCT } from '../../utils/routers';

function Pet() {
  const { goTo } = useNavigateTo();

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='펫보험' path={HOME} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container pet gray02'>
        <div className='guide'>
          <div className='pet_intro'>
            <div className='inner_white_box type01 mt_20'>
              <button
                className='dis_flex justify_between w_full'
                onClick={() => goTo(PET_HIPET_PRODUCT)}
              >
                <span className='col_orange fs_18 fw_700'>하이펫 애견보험</span>
                <i className='r_arrow_g_icon02'></i>
              </button>
              <p className='fs_14 fw_500 col_gray2'>저렴한 보험료, 높은 보장금액!</p>
              <div className='orange_box mt_16'>
                <i className='orange_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  입통원 의료비 업계 최고 하루당
                  <br />
                  <span className='col_orange'>보상한도 300만원!</span>
                </div>
              </div>
              <div className='orange_box mt_12'>
                <i className='orange_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  순수보장형 합리적인 보험료로
                  <br />
                  <span className='col_orange'>연간최대 1천만원까지 보상!</span>
                </div>
              </div>
              <div className='orange_box mt_12'>
                <i className='orange_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  의료비는 물론 <span className='col_orange'>장례비까지 보장!</span>
                </div>
              </div>
            </div>

            <div className='inner_white_box type01 mt_16'>
              <button
                className='dis_flex justify_between w_full'
                onClick={() => goTo(PET_GOODANDGOOD_PRODUCT)}
              >
                <span className='col_darkblue fs_18 fw_700'>굿앤굿 우리펫보험</span>
                <i className='r_arrow_g_icon02'></i>
              </button>
              <p className='fs_14 fw_500 col_gray2'>폭 넒은 보장과 긴 보장기간!</p>
              <div className='blue_box mt_16'>
                <i className='blue_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  입원, 통원 수술의료비
                  <br />
                  자동 갱신으로 <span className='col_blue'>최대 20세까지 길게 보장</span>
                </div>
              </div>
              <div className='blue_box mt_12'>
                <i className='blue_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  슬개관절 탈구 보장은 물론 피부질환, 구강질환(치과 포함),{' '}
                  <span className='col_blue'>MRI/CT 검사비까지 보장</span>
                </div>
              </div>
              <div className='blue_box mt_12'>
                <i className='blue_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  아포퀠, 사이토포인트 등 특정 약물 및<br />
                  <span className='col_blue'>특정 처치 비용도 보장</span>
                </div>
              </div>
              <div className='blue_box mt_12'>
                <i className='blue_chk_icon'></i>
                <div className='fs_15 fw_700 txt_center'>
                  보험 가입 즉시 <span className='col_blue'>최대 13% 할인</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- // container --> */}
    </div>
  );
}

export default Pet;
