import React from 'react';

interface Props {
  bottomSheetRef: React.MutableRefObject<HTMLDivElement | null>;
  toggleBottomSheet: (isOpen: boolean) => void;
}
function DocumentsSheet({ bottomSheetRef, toggleBottomSheet }: Props) {
  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const handleFileUpload = (() => {
    let isCalled = false;
    return (type: string, event: React.MouseEvent<HTMLLIElement>) => {
      if (isCalled) return;
      isCalled = true;
      setTimeout(() => {
        isCalled = false;
      }, 0);

      event.preventDefault();
      event.stopPropagation();

      console.log('Sending message to WebView:', type);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(type);
      } else {
        console.error('ReactNativeWebView가 정의되지 않았습니다.');
      }
    };
  })();

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      toggleBottomSheet(false);
    }
  };

  return (
    <div
      ref={bottomSheetRef}
      className='bottom_sheet_pop'
      id='btmSheet01'
      onClick={handleBackgroundClick}
    >
      <div className='pop_wrap pd_type2'>
        <div className='pop_tit'>증빙서류 첨부</div>
        <div className='pop_body'>
          <div className='inner_gray_box square'>
            <div className='fs_13'>
              · 서류를 접힌 부분 없이 깔끔하게 펴서 촬영해 주세요.
              <br />
              · 촬영 시 카메라 초점을 흐려지지 않게 맞춰 주세요.
              <br />· 그림자가 지지 않도록 배치하여 촬영해 주세요.
            </div>
          </div>
          <ul className='bill_file_add_list mt_18'>
            {/*<li className='item' onClick={evnet => handleFileUpload('openCamera', evnet)}>*/}
            {/*  <input id='fileInp_camera' className='hide' />*/}
            {/*  <label htmlFor='fileInp_camera' className='label'>*/}
            {/*    <i className='camera_icon'></i>*/}
            {/*    <span className='flex1 fs_16 fw_500'>카메라</span>*/}
            {/*    <i className='r_arrow_icon'></i>*/}
            {/*  </label>*/}
            {/*</li>*/}
            <li className='item' onClick={evnet => handleFileUpload('openGallery', evnet)}>
              <input id='fileInp_gallery' className='hide' />
              <label htmlFor='fileInp_gallery' className='label'>
                <i className='photo_icon'></i>
                <span className='flex1 fs_16 fw_500'>앨범</span>
                <i className='r_arrow_icon'></i>
              </label>
            </li>
            <li className='item' onClick={evnet => handleFileUpload('openFile', evnet)}>
              <input id='fileInp_file' className='hide' />
              <label htmlFor='fileInp_file' className='label'>
                <i className='clip_icon'></i>
                <span className='flex1 fs_16 fw_500'>내 파일</span>
                <i className='r_arrow_icon'></i>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DocumentsSheet;
