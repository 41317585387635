import React, { useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { PET } from '../../utils/routers';
import Tab from '../../components/pet/Tab';
import useCurrentPath from '../../hooks/useCurrentPath';
import * as yup from 'yup';
import { TDefaultResult, useCustomMutation } from '../../api/apiHooks';
import { useFormik } from 'formik';
import endpointConfig from '../../config/endpoint';
import { AxiosError } from 'axios';
import useBottomSheet from '../../hooks/useBottomSheet';
import { getDogClassificationTxt, lPets, mPets, sPets } from '../../utils/pets';
import { formatDateWithHyphens, formatKoreanPhoneNumber } from '../../utils/format';
import AlertModal from '../../components/modal/AlertModal';
import LinkModal from '../../components/modal/LinkModal';
import useNavigateTo from '../../hooks/useNavigateTo';

const dogClassifications = [
  { id: 'rad01', value: 's', label: '소형견' },
  { id: 'rad02', value: 'm', label: '중형견' },
  { id: 'rad03', value: 'l', label: '대형견' },
];

const validationSchema = yup.object({
  dogName: yup
    .string()
    .trim()
    .required('애견명을 입력해주세요.')
    .min(2, '최소 두자 이상 입력해주세요.'),
  dogClassification: yup.string().trim().required('견종을 선택해주세요.'),
  dogBreed: yup.string().trim().required('견종을 선택해주세요.'),
  dogBirthDate: yup
    .string()
    .trim()
    .required('생년월일을 입력해주세요.')
    .min(10, '올바른 생년월일을 입력해주세요.'),
  guardianPhone: yup
    .string()
    .trim()
    .required('휴대폰번호를 입력해주세요.')
    .min(13, '휴대폰번호를 입력해주세요.'),
  consultationTime: yup.string().required('상담 요청 시간을 선택해주세요.'),
  isAgree: yup
    .boolean()
    .oneOf([true], '개인정보 수집 및 이용에 대한 동의를 선택해주세요.')
    .required('개인정보 수집 및 이용에 대한 동의를 선택해주세요.'),
});

export type TPetReq = {
  type: string;
  dogName: string;
  dogBreed: string;
  dogBirthDate: string;
  guardianPhone: string;
  consultationTime: string;
};

function PetRegister() {
  const pathname = useCurrentPath();
  const isHipetPage = pathname.includes('hipet');
  const { goTo } = useNavigateTo();

  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { bottomSheetRef: bottomSheetRef1, toggleBottomSheet: toggleBottomSheet1 } =
    useBottomSheet();
  const { bottomSheetRef: bottomSheetRef2, toggleBottomSheet: toggleBottomSheet2 } =
    useBottomSheet();
  const [dogBreedList, setDogBreedList] = useState<{ label: string; value: string }[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                                     api                                    */
  /* -------------------------------------------------------------------------- */
  // 회원가입
  const mutation = useCustomMutation<TPetReq>(endpointConfig.petRegister, 'post', 0, {
    onSuccess: (data: TDefaultResult) => {
      if (data.status) {
        setIsSuccess(true);
      } else {
        setErrorMsg(data.message);
      }
    },
    onError: (error: AxiosError) => {
      setErrorMsg((error.response as any).data.message);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                 formik 상태 값                                */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: {
      dogName: '',
      dogClassification: '',
      dogBreed: '',
      dogBirthDate: '',
      guardianPhone: '',
      consultationTime: '',
      isAgree: false,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const newPayload = {
        type: isHipetPage ? 'HI_PET' : 'GOOG_AND_GOOD',
        dogName: values.dogName.trim(),
        dogBreed: `${getDogClassificationTxt(values.dogClassification.trim())}/${values.dogBreed.trim()}`,
        dogBirthDate: values.dogBirthDate.trim(),
        guardianPhone: values.guardianPhone.trim(),
        consultationTime: values.consultationTime.trim(),
      };

      // pet 상담요청 api 호출
      mutation.mutate(newPayload);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   hanlder                                  */
  /* -------------------------------------------------------------------------- */
  // 견종선택 > 분류
  const handleDogClassification = (size: string) => {
    formik.setFieldValue('dogClassification', size);
    formik.setFieldValue('dogBreed', ''); // 견종 초기화

    // 분류에 따른 견종 리스트 상태 값 업데이트
    if (size === 's') setDogBreedList(sPets);
    else if (size === 'm') setDogBreedList(mPets);
    else if (size === 'l') setDogBreedList(lPets);
    else setDogBreedList([]);

    // 모달 닫기
    toggleBottomSheet1(false);
  };

  // 견종선택 > 견종
  const handleDogBreed = (dogBreed: string) => {
    formik.setFieldValue('dogBreed', dogBreed);
    toggleBottomSheet2(false);
  };
  const stickyStyle = { position: "sticky", left: 0, top: 0 };
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader
          title={isHipetPage ? '하이펫 애견보험' : '굿앤굿 우리펫보험'}
          path={PET}
          hasHomeBtn={true}
          tab={<Tab />}
        />
        {/* <!-- // header --> */}
        <form onSubmit={formik.handleSubmit} style={{display: 'contents'}}>
          {/* <!-- container --> */}
          <div className='container pet'>
            <div className='guide pb_32'>
              <div
                className={`inp_wrap mt_56 ${formik.errors.dogName && formik.touched.dogName ? 'state_red' : ''}`}
              >
                <label className='label'>
                  애견명 <span className='col_red'>*</span>
                </label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='이름을 입력해 주세요'
                    name='dogName'
                    value={formik.values.dogName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.dogName && formik.touched.dogName && (
                  <div className='state_txt'>{formik.errors.dogName}</div>
                )}
              </div>

              <div
                className={`inp_wrap mt_16 ${(formik.errors.dogClassification && formik.touched.dogClassification) || (formik.errors.dogBreed && formik.touched.dogBreed) ? 'state_red' : ''}`}
              >
                <label className='label'>
                  견종선택 <span className='col_red'>*</span>
                </label>
                <div className='dis_flex gap12'>
                  <div className='flex1'>
                    <button
                      onClick={() => toggleBottomSheet1(true)}
                      type='button'
                      className='select_ui_btn'
                      style={{
                        borderColor:
                          formik.errors.dogClassification && formik.touched.dogClassification
                            ? '#DC0000'
                            : '',
                      }}
                    >
                      {getDogClassificationTxt(formik.values.dogClassification)}
                    </button>
                  </div>
                  <div className='flex1'>
                    <button
                      onClick={() => {
                        if (!formik.values.dogClassification) return;
                        toggleBottomSheet2(true);
                      }}
                      type='button'
                      className='select_ui_btn'
                      style={{
                        borderColor:
                          formik.errors.dogBreed && formik.touched.dogBreed ? '#DC0000' : '',
                        lineHeight: '2rem',
                      }}
                    >
                      {formik.values.dogBreed ? formik.values.dogBreed : '견종 선택'}
                    </button>
                  </div>
                </div>
                {((formik.errors.dogClassification && formik.touched.dogClassification) ||
                  (formik.errors.dogBreed && formik.touched.dogBreed)) && (
                  <div className='state_txt'>견종을 선택해주세요.</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.dogBirthDate && formik.touched.dogBirthDate ? 'state_red' : ''}`}
              >
                <label className='label'>
                  애견 생년월일 <span className='col_red'>*</span>
                </label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='생년월일을 입력해 주세요.'
                    maxLength={10}
                    name='dogBirthDate'
                    value={formik.values.dogBirthDate}
                    onChange={e => {
                      const newValue = formatDateWithHyphens(e.target.value);
                      formik.setFieldValue('dogBirthDate', newValue);
                    }}
                  />
                </div>
                {formik.errors.dogBirthDate && formik.touched.dogBirthDate && (
                  <div className='state_txt'>{formik.errors.dogBirthDate}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.guardianPhone && formik.touched.guardianPhone ? 'state_red' : ''}`}
              >
                <label className='label'>
                  보호자 휴대폰 번호 <span className='col_red'>*</span>
                </label>
                <div className='inp'>
                  <input
                    type='text'
                    placeholder='휴대전화 번호를 입력해 주세요.'
                    maxLength={13}
                    name='guardianPhone'
                    value={formik.values.guardianPhone}
                    onChange={e => {
                      const newValue = formatKoreanPhoneNumber(e.target.value);
                      formik.setFieldValue('guardianPhone', newValue);
                    }}
                  />
                </div>
                {formik.errors.guardianPhone && formik.touched.guardianPhone && (
                  <div className='state_txt'>{formik.errors.guardianPhone}</div>
                )}
              </div>
              <div
                className={`inp_wrap mt_16 ${formik.errors.consultationTime && formik.touched.consultationTime ? 'state_red' : ''}`}
              >
                <label className='label'>
                  상담 요청 시간<span className='col_red'>*</span>
                </label>
                <div className='inp'>
                  <select
                    style={{
                      border:
                        formik.errors.consultationTime && formik.touched.consultationTime
                          ? '1px solid red'
                          : '',
                    }}
                    onChange={e => {
                      formik.setFieldValue('consultationTime', e.target.value);
                    }}
                  >
                    <option disabled selected>
                      선택
                    </option>
                    <option value='09:00~11:00'>09:00~11:00</option>
                    <option value='13:00~15:00'>13:00~15:00</option>
                    <option value='15:00~17:00'>15:00~17:00</option>
                  </select>
                </div>
                {formik.errors.consultationTime && formik.touched.consultationTime && (
                  <div className='state_txt'>{formik.errors.consultationTime}</div>
                )}
              </div>
            </div>
            <div className='guide gray_bortop'>
              <div className='inp_wrap mt_16'>
                <label className='label'>
                  개인정보 수집 및 이용에 대한 동의 <span className='col_red'>*</span>
                </label>
                <div className='inp'>
                  <pre className='inner_gray_box my-element'>
                    가입상담문의 응대를 위한 개인(신용)정보 처리 상세 동의서
                    <br />
                    <br />ㅁ 수집,이용에 관한 사항
                    <br />
                    수집목적: 상품,서비스소개,홍보및판매
                    <br />
                    보유 및 이용기간: 동의일로부터 1년 또는 동의 철회 시까지
                    <br />
                    거부 권리 및 불이익: 귀하는 아래 개인(신용)정보 수집·이용에 관한 동의를 거부하실
                    수 있습니다. 다만, 동의하지 않으실 경우 "가입상담 등"의 혜택을 받지 못할 수
                    있습니다.
                    <br />
                    <br />
                    ㅁ 수집,이용항목
                    <br />
                    개인(신용)정보: 일반개인정보(성명,성별,생년월일,휴대전화번호 신청정보에 한함) *
                    본 동의는 당사와 모집위탁계약을 체결한 업무수탁자가 수집·이용하는 것을 포함하며,
                    동의하시는 경우 임직원 또는 업무수탁자가 안내연락을 드릴 수 있습니다.
                    <br />※ 업무수탁자란 현대해상 전속 또는 비전속 보험설계사 및 동 계약을 모집한
                    보험대리점
                    <br /> <br />본 동의는 보험계약 상담을위한 필수동의 입니다. 동의하시더라도
                    담당자(010-8188-3951)를 통해 철회하거나 가입 권유 목적의 연락에 대한 중단을
                    요청하실 수 있으며 연락중지청구시스템(www.donotcall.or.kr)를 통해 언제든지
                    마케팅활동에 대한 중지를 요청하실 수 있습니다. 동의하시는 경우 현대해상 펫보험
                    상품의 안내 및 판매권유 등의 목적으로 개인정보를 이용하여 연락을 드릴 수
                    있습니다.
                  </pre>
                </div>
              </div>

              <div className='mt_12'>
                <div className='checkbox'>
                  <input
                    id='chk01'
                    type='checkbox'
                    checked={formik.values.isAgree}
                    onClick={() => {
                      formik.setFieldValue('isAgree', !formik.values.isAgree);
                    }}
                  />
                  <label htmlFor='chk01' className='fs_16 fw_600'>
                    동의합니다
                  </label>
                  {formik.errors.isAgree && formik.touched.isAgree && (
                    <div className='state_txt mt_8 fs_13 col_red'>{formik.errors.isAgree}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- // container --> */}

          {/* <!-- foot_container --> */}
          <div className='foot_container' style={{position:'sticky', left: 0, bottom: 0}}>
            <div className='foot_btn'>
              <button className='btn orange' type='submit'>
                보험료 알아보기
              </button>
            </div>
          </div>
          {/* <!-- // foot_container --> */}
        </form>
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      <div className='bottom_sheet_pop' id='btmSheet01' ref={bottomSheetRef1}>
        <div className='pop_wrap pd_type2'>
          <div className='pop_tit'>견종 선택</div>
          <div className='pop_body'>
            <div className='checkbox_list02'>
              {dogClassifications.map(({ id, value, label }) => (
                <div
                  className='checkbox02'
                  key={id}
                  onClick={() => {
                    handleDogClassification(value);
                    toggleBottomSheet1(false);
                  }}
                >
                  <input
                    type='radio'
                    name='rad00'
                    id={id}
                    checked={formik.values.dogClassification === value}
                  />
                  <label htmlFor={id}>{label}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      <div className='bottom_sheet_pop' id='btmSheet02' ref={bottomSheetRef2}>
        <div className='pop_wrap pd_type2 '>
          <div className='pop_tit'>견종 선택</div>
          <div className='pop_body'>
            <div className='checkbox_list02 pop_max_height'>
              {dogBreedList.map((dog, i) => (
                <div
                  className='checkbox02'
                  key={dog.label}
                  onClick={() => {
                    handleDogBreed(dog.value);
                    toggleBottomSheet2(false);
                  }}
                >
                  <input
                    type='radio'
                    name='rad10'
                    id={`rad1${i}`}
                    checked={formik.values.dogBreed === dog.value}
                  />
                  <label htmlFor={`rad1${i}`}>{dog.value}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <AlertModal
        title='상담신청 실패'
        desc={errorMsg}
        onClose={() => setErrorMsg('')}
        sx={{ display: errorMsg ? 'block' : 'none' }}
      />
      <LinkModal
        title='보험가입요청이 정상적으로 완료되었습니다.'
        desc={'담당자가 확인 후 연락 드리겠습니다.'}
        hasClose={false}
        onLink={() => goTo(PET)}
        sx={{ display: isSuccess ? 'block' : 'none' }}
        linkTxt='확인'
      />
    </>
  );
}

export default PetRegister;
