import React, { useEffect, useState } from 'react';
import Header from '../../components/common/Header';
import useNavigateTo from '../../hooks/useNavigateTo';
import {
  DOMESTIC_TRAVEL_INSURANCE,
  GROUP_ACCIDENT_INSURANCE,
  LOGIN,
  LONG_TRAVEL_INSURANCE,
  OVERSEAS_TRAVEL_INSURANCE,
  PET,
  REQUIRED_DOC_INFO,
  COMPANY_INSURANCE_CODE,
} from '../../utils/routers';
import Footer from '../../components/common/Footer';
import { checkLogin } from '../../utils/auth';
import LinkModal from '../../components/modal/LinkModal';
import Banner from '../../components/home/Banner';
import { LONG_TRAVEL_STORAGE_KEYS } from '../../hooks/useLongTravelFunnel';
import { GROUP_ACCIDENT_STORAGE_KEYS } from '../../hooks/useGroupAccidentFunnel';
import { DOMESTIC_TRAVEL_STORAGE_KEYS } from '../../hooks/useDomesticTravelFunnel';
import { OVERSEAS_TRAVEL_STORAGE_KEYS } from '../../hooks/useOverseasTravelFunnel';
import { COMPANY_INSURANCE_STORAGE_KEYS } from '../../hooks/useCompanyInsuranceFunnel';

function Home() {
  const { goTo } = useNavigateTo();
  const [isNeedLogin, setIsNeedLogin] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                   hanlder                                  */
  /* -------------------------------------------------------------------------- */
  const handleNavigation = (path: string) => {
    if (checkLogin()) {
      goTo(path);
    } else {
      setIsNeedLogin(true);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const fcmToken = searchParams.get('fcmToken');

    if (fcmToken) {
      localStorage.setItem('fcmToken', fcmToken);
      console.log('FCM Token 저장:', fcmToken);
    } else {
      console.log('fcmToken이 URL에 없습니다.');
    }

    console.log('현재 URL:', window.location.pathname);
  }, [window.location.search]);

  useEffect(() => {
    Object.values(LONG_TRAVEL_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });

    Object.values(GROUP_ACCIDENT_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });

    Object.values(DOMESTIC_TRAVEL_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });

    Object.values(OVERSEAS_TRAVEL_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });

    Object.values(COMPANY_INSURANCE_STORAGE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
  }, []);

  return (
    <>
      <div className='wrap'>
        <Header />

        {/* container */}
        <div className="container main">
          <div className="guide">
            <div className="sect01">
              <div>
                <div className="tit">단체 상해 보험</div>
                <div className="desc">
                  간편하게 단체 보험금 청구를
                  <br />
                  진행해 보세요.
                </div>
              </div>
              <button
                className="btn blue"
                onClick={() => handleNavigation(GROUP_ACCIDENT_INSURANCE)}
              >
                보험금 청구
              </button>
              <img src="/image/banner/main_sect01_banner.png" className="banner_img" />
            </div>
          </div>

          <div className="guide">
            <div className="sect03">
              <button className="item" onClick={() => goTo(LONG_TRAVEL_INSURANCE)}>
                장기체류보험
                <i className="idcard_icon"></i>
              </button>
              <button className="item" onClick={() => goTo(OVERSEAS_TRAVEL_INSURANCE)}>
                해외여행보험
                <i className="airplane_icon"></i>
              </button>
              <button className="item" onClick={() => goTo(DOMESTIC_TRAVEL_INSURANCE)}>
                국내여행보험
                <i className="inter_icon"></i>
              </button>
              <button className="item" onClick={() => goTo(PET)}>
                펫보험
                <i className="dogfoot_icon"></i>
              </button>
              <button style={{ lineHeight: '18px', paddingTop: 20 }} className="item"
                      onClick={() => goTo(COMPANY_INSURANCE_CODE)}>
                기업보험<br />사고접수
                <i className="company_icon"></i>
              </button>
              <button className="item" onClick={() => goTo(REQUIRED_DOC_INFO)}>
                필요 서류 안내
                <i className="document_icon"></i>
              </button>
            </div>
          </div>


          {/*<div className="guide">*/}
          {/*  <div className="sect04">*/}
          {/*    <button className="item" onClick={() => goTo(REQUIRED_DOC_INFO)}>*/}
          {/*      <div>*/}
          {/*        <span className="tit">필요 서류 안내</span>*/}
          {/*        <div className="desc">사고유형별 필요서류를 확인해 보세요</div>*/}
          {/*      </div>*/}
          {/*      <img src="/image/banner/main_sect04_banner.png" alt="" className="img_banner" />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="guide">
            <div className="sect02">
              <Banner />
            </div>
          </div>
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* modal */}
      <LinkModal
        title="로그인"
        desc={
          <div className='msg'>
            로그인이 필요한 서비스 입니다
            <br />
            로그인 화면으로 이동합니다
          </div>
        }
        onClose={() => setIsNeedLogin(false)}
        onLink={() => goTo(LOGIN)}
        sx={{ display: isNeedLogin ? 'block' : 'none' }}
        linkTxt='확인'
      />
    </>
  );
}

export default Home;
