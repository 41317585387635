import React, { useEffect, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import { COMPANY_INSURANCE_CODE, COMPANY_INSURANCE_STEP1, COMPANY_INSURANCE_STEP5 } from '../../utils/routers';
import ImageWithTextOverlay from '../../components/groupAccidentInsurance/ImageWithTextOverlay';
import useGroupAccidentFunnel from '../../hooks/useGroupAccidentFunnel';
import { formatKoreanDate } from '../../utils/date';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';

function CompanyInsuranceStep4() {
  const { goTo } = useNavigateTo();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const { finalValues } = useCompanyInsuranceFunnel();
  const [form, setForm] = useState<any>(undefined);
  const [isReady, setIsReady] = useState(false);
  const [image, setImage] = useState('');
  useEffect(() => {
    const newForm: any = { ...finalValues };

    if(newForm?.damageType !== 1){
      setImage('/html/group/company/img/company01.png');
    }else {
      setImage('/html/group/company/img/company02.png');
    }

    if (newForm.damageType === 1) {
      newForm.addressFull = newForm.address+' '+newForm.addressDetail;
      newForm.damageSummary = '';
      newForm.expectedDamageAmount = '';
      newForm.etcNote = '';
    }else {
      newForm.addressFull = '';
      newForm.victimPhone = '';
      newForm.victimName = '';
      newForm.birth = '';
      newForm.deputy = '';
      newForm.victimNote = '';
      newForm.injuries = '';
      newForm.relationship = '';
    }
    let today = new Date();
    newForm.createdAt = formatKoreanDate(new Date());
    newForm.year = today.getFullYear();
    newForm.month = ('0' + (today.getMonth() + 1)).slice(-2);
    newForm.day = ('0' + today.getDate()).slice(-2);
    //
    if (JSON.stringify(form) !== JSON.stringify(newForm)) {
      setForm(newForm);
      setIsReady(true);
    }
  }, [finalValues]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='기업보험' cancel={true} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container gray02'>
        <div className='guide bg_white'>
          <div className='dis_flex justify_between h_56'>
            <div className='fs_18 fw_600'>5. 청구서 미리보기</div>
            <button
              className='col_blue fw_600 underline'
              onClick={() => goTo(COMPANY_INSURANCE_CODE)}
            >
              수정하기
            </button>
          </div>
          {/*<div className='tab_btns_wrap type01 bortop_1'>*/}
          {/*  <div className='tab_btns'>*/}
          {/*    {menus.map((menu, index) => (*/}
          {/*      <p*/}
          {/*        key={index}*/}
          {/*        className={`tab_btn ${selectedMenu === index ? 'active' : ''}`}*/}
          {/*        onClick={() => setSelectedMenu(index)}*/}
          {/*      >*/}
          {/*        {menu}*/}
          {/*      </p>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className='guide mt_12'>
          {
            <>
              {isReady &&
                <ImageWithTextOverlay
                  imageUrl={image}
                  userData={form}
                  template={`group11_0`}
                  signaturePreview={finalValues?.claimantSignaturePreview || ''}
                />
              }
                {/*// images[selectedMenu].map((image, index) => (*/}
                {/*//   <ImageWithTextOverlay*/}
                {/*//     imageUrl={image}*/}
                {/*//     userData={form}*/}
                {/*//     template={`group11_0`}*/}
                {/*//     signaturePreview={finalValues?.claimantSignaturePreview || ''}*/}
                {/*//   />*/}
                {/*// ))}*/}
            </>
          }
        </div>

      </div>
      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={() => goTo(COMPANY_INSURANCE_STEP5)}>
            증빙서류 첨부
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default CompanyInsuranceStep4;

const menus = [
  '재물',
  'DB손해보험',
];

export const images = [
  [
    '/html/group/company/img/company01.png',
  ],
  [
    '/html/group/company/img/company02.png',
  ],
];
