import React, { RefObject, useState, useRef, useEffect } from 'react';
import useNavigateTo from '../hooks/useNavigateTo';
import { Link } from 'react-router-dom';

interface CautioninfoProps {
  bottomSheetRef: RefObject<HTMLDivElement>;
  toggleBottomSheet: (isOpen: boolean) => void;
  name: string;
}

const Cautioninfo: React.FC<CautioninfoProps> = ({ bottomSheetRef, toggleBottomSheet, name }) => {
  const { goTo } = useNavigateTo();
  const [isScrollable, setIsScrollable] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          setIsScrollable(true);
        }
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      return () => contentElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleNext = () => {
    goTo(`${name}`);
  }

  return (
    <div ref={bottomSheetRef} className='bottom_sheet_pop main_bill_pop' id='btmSheet01'>
      <div className='pop_wrap pd_type2' style={{ height: '95%' }}>
        <button className='x_icon popClose' onClick={() => toggleBottomSheet(false)}></button>
        <div className='pop_tit'>보험가입 유의사항 안내</div>
        <div ref={contentRef} className='pop_body info'>
          ○ 상품내용에 대한 확인
          <p style={{fontSize: 14, fontWeight: 400, paddingTop: 8, paddingBottom: 20, lineHeight: '20px', color: '#767676'}}>금융소비자는 보험설계사 등 모집종사자 및 보험회사로부터 해당 상품에 대해 충분한 설명을 받을 권리가 있으며, 그 설명을 이해한 후 거래하시기 바랍니다.</p>
          ○ 모집종사자 관련 사항
          <p>보험모집인 사이먼글로벌보험중개㈜는 메리츠화재해상보험주식회사의 모집인이며, 보험계약 체결권한은 보험모집인에게 있지 않고, 보험사에 있습니다.</p>
          ○ 해약환급금에 관한 사항
          <p>보험은 은행의 저축과 달리 위험보장과 저축을 겸비한 제도로서 보험계약자가 납입한 보험료 중 일부는 불의의 사고를 당한 다른 가입자에게 지급되는 보험금으로, 또 다른 일부는 보험회사 운영에
            필요한 경비로 사용되므로 중도 해지 시 지급되는 해약환급금은 납입한 보험료보다 적거나 없을 수도 있습니다. </p>
          ○ 청약의 철회
          <p>일반금융소비자인 계약자는 보험증권을 받은 날부터 15일 이내(청약을 한 날부터 30일 이내(65세 이상 계약자가 전화를 이용하여 계약을 체결한 경우 청약을 한 날부터 45일 이내)에 한함)에
            그 청약을 철회할 수 있으며, 이 경우 철회를 접수한 날부터 3영업일 이내에 보험료를 돌려 드립니다. 다만, 다음 중 어느 하나에 해당하는 경우에는 보험계약의 청약을 철회할 수 없습니다.
            ① 회사가 건강상태 진단을 지원하는 계약
            ② 보험기간이 90일 이내인 계약
            ③ 청약의 철회를 위해 제3자의 동의가 필요한 보증보험
            ④ 법률에 따라 가입의무가 부과되고 그 해제·해지도 해당 법률에 따라 가능한 보장성 상품(다만, 일반금융소비자가 동종의 다른 보험에 가입한 경우는 제외)
            ⑤ 「자동차손해배상 보장법」에 따른 책임보험
            (다만, 일반금융소비자가 동종의 다른 책임보험에 가입한 경우는 제외)
            ⑥ 전문금융소비자 체결한 계약
          </p>
          ○ 품질보증제도
          <p>계약자가 청약한 경우 약관과 계약자 보관용 청약서를 청약 시 전달받지 못하거나 약관의 중요한 내용을 설명 받지 못한 때 또는 청약서에 자필서명을 하지 않은 때에는 계약자는 계약이 성립한 날부터
            3개월 이내에 계약을 취소할 수 있습니다. 이 경우 이미 납입한 보험료에 보험료를 받은 기간에 대해 이 계약의 보험계약대출이율을 연단위 복리로 계산한 금액을 더하여 지급합니다. </p>
          ○ 가입자의 계약 전/후 알릴 의무
          <p><span style={{ color: '#111' }}>▪ 계약 전 알릴의무</span>
            <br />
            - 계약자 또는 피보험자 등은 보험계약 청약 시 과거의 건강 상태, 직업 등 청약서의 기재사항 및 질문사항에 대하여 알고 있는 내용을 반드시 사실대로 알려야 하며, 그렇지 않은 경우 보험금의
            지급이 거절되거나 계약이 해지될 수 있습니다.
            <br />
            <br />
            <span style={{ color: '#111' }}>▪ 계약 후 알릴의무</span>
            <br />
            - 계약자 또는 피보험자는 보험계약을 맺은 후 직업변경 등 보험약관에 정한 계약 후 알릴 의무 사항이 발생하였을 경우 지체 없이 회사에 알리고 보험증권에 확인을 받아야 합니다. 그렇지 않을 경우
            보험금의 지급이 거절되거나 계약이 해지될 수 있습니다.</p>
        </div>
        <div>
          <div style={{display: 'flex', alignItems: 'center', padding: '1rem 2.6rem 0'}}>
            <div className='checkbox' style={{paddingRight: '0.5rem'}}>
              <input
                id={'check'}
                type='checkbox'
                checked={isChecked}
                disabled={!isScrollable}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor={'check'}> 위 내용을 충분히 확인하였습니다.</label>
            </div>
          </div>
          <div className='foot_container'>
            <div className='foot_btn'>
              {/* <button className={`btn blue ${formik.values.accessCode ? '' : 'disabled'}`} onClick={handleNext}>*/}
              <button className={`btn blue ${!isChecked ? 'disabled' : ''}`} onClick={handleNext} disabled={!isChecked}>
                다음
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cautioninfo;
