import React, { useEffect } from 'react';
import Routes from './Routes';
import 'swiper/css'; // 최신 버전의 Swiper CSS 경로
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { dehydrate, hydrate } from 'react-query';
import queryClient from './api/queryClient';
import { ls } from './utils/localStorage';
import useNavigateTo from './hooks/useNavigateTo';
import { GROUP_ACCIDENT_INSURANCE, HOME, MYPAGE } from './utils/routers';
import { checkLogin } from './utils/auth';
import useCurrentPath from './hooks/useCurrentPath';
import Pc from './pages/pc/Pc';

function App() {
  const currentPath = useCurrentPath(); // 현재 경로 가져오기
  const { goTo } = useNavigateTo();

  useEffect(() => {
    const cachedData = localStorage.getItem(ls.queryCache);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      hydrate(queryClient, parsedData);
    }
  }, []);

  // 캐시 상태가 변경될 때마다 로컬 스토리지를 업데이트
  useEffect(() => {
    const cacheInterval = setInterval(() => {
      localStorage.setItem(ls.queryCache, JSON.stringify(dehydrate(queryClient)));
    }, 1000);

    return () => clearInterval(cacheInterval);
  }, []);

  useEffect(() => {
    // /mypage가 포함된 URL에서만 로그인 체크
    if (currentPath.includes(MYPAGE) && !checkLogin()) {
      goTo(HOME); // 로그인하지 않은 경우 홈으로 리다이렉트
    }

    // /long-travel-insurance 포함된 URL에서만 로그인 체크
    if (currentPath.includes(GROUP_ACCIDENT_INSURANCE) && !checkLogin()) {
      goTo(HOME); // 로그인하지 않은 경우 홈으로 리다이렉트
    }
  }, [currentPath, goTo]);

  /* 빌드시 주석해제 */
  // console = {};
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <>
      <div className='mobile_wrap'>
        <Routes />
      </div>
      <Pc />
    </>
  );
}

export default App;
