import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

function CreditProvision() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='개인(신용)정보 제공에 관한 동의' terms={true} />

        {/* <!-- container --> */}
        <div style={styles.container}>
          <h1 style={styles.sectionTitle}>[필수] 보험금청구를 위한 상세동의서</h1>

          <p style={styles.note}>
            귀하는 개인(신용)정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의를 철회할 수 있습니다.
            다만, 본 동의는 ‘보험금 청구’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무 수행이 불가능 합니다.
          </p>

          {/* 수집·이용에 관한 사항 */}
          <section>
            <h2 style={styles.sectionTitle}>1. 제공에 관한 사항</h2>

            <h3 style={styles.sectionTitle}>• 제공받는자</h3>
            <ul style={styles.list}>
              <li>① 국가기관 등: 금융위원회, 국토교통부 금융감독원 국세청 보험요율산출기관 등 법령상 업무수행기관(위탁사업자 포함)</li>
              <li>② 종합신용정보집중기관 : 한국신용정보원</li>
              <li>③ 보험회사 등: 생명·손해보험회사. 국내재보험사, 국외재보험사, 공제사업자, 체신관서(우체국보험)</li>
              <li>④ 금융거래기관: 계좌개설 금융기관, 금융결제원</li>
              <li>⑤ 계약관계자: 피보험자, 보험금 청구권자</li>
              <li>⑥ 보험협회</li>
            </ul>

            <h3 style={styles.sectionTitle}>• 제공받는자의 이용목적</h3>
            <ul style={styles.list}>
              <li>
                ① 국가기관 등: 법령에 따른 업무수행(위탁업무 포함)
              </li>
              <li>
                ② 종합신용정보집중기관 : 개인(신용)정보 조회, 신용정보의 집중관리 및 활용 등 법령에서 정한 종합신용정보 집중기관의 업무 수행, 교통사고처리내역발급간소화 서비스
              </li>
              <li>③ 보험회사 등: 중복보험 확인 및 비례보상, 재보험청구, 보험사고조사(보험사기 조사 포함), 구상관련업무</li>
              <li>④ 금융거래기관: 금융거래업무</li>
              <li>⑤ 계약관계자: 손해사정내용 관련 정보 제공</li>
              <li>⑥ 보험협회: 보험금 지급·심사 관련 업무지원(보험금 청구서류 접수대행 서비스, 자동차과실비율분쟁심의업무 등) * 자동차보험에 한함</li>
            </ul>
            <p style={styles.note}>
              • 보유 및 이용기간: 제공받는 자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
            </p>
            <p style={styles.note}>
              ※ 외국 재보험사의 국내 지점이 재보험금 청구 등 지원 업무를 위탁하기 위한 경우 별도의 동의 없이 외국 소재 본점에 귀하의 정보를 이전할 수 있습니다.
            </p>

            <h3 style={styles.sectionTitle}>• 제공항목</h3>
            <ul style={styles.list}>
              <li style={{fontWeight: 500, color: '#000'}}>
                국내
              </li>
              <li>
                - 고유식별정보: 주민등록번호, 외국인등록번호, 여권번호, 운전면허번호
              </li>
              <li>- 민감정보: 피보험자의 질병·상해에 관한 정보(진료기록,상병명 등), 보험사고 조사(보험사기포함)및 손해사정 업무 수행과 관련하여 취득한 정보(경찰,공공·국가기관,의료기관 등으로부터
                본인의 위임을 받아 취득한 각종 조사서, 진료기록 등에 포함된 개인(신용)정보포함)
              </li>
              <li>- 개인(신용)정보</li>
              <li>일반개인정보: 성명, 주소, 성별, 직업, 유무선 전화번호, 이메일, 운전면허정보, 가족관계증명</li>
              <li>신용거래정보: 금융거래 업무 관련 정보(보험금 지급계좌 등), 보험계약정보(상품종류,기간,보험가입금액등), 보험금정보(보험금 지급사유, 지급금액등)</li>
              <li style={{paddingTop: 10, fontWeight: 500, color: '#000'}}>
                국외
              </li>
              <li>
                - 개인(신용)정보일반개인정보: 성명, 성별, 연령
              </li>
              <li>신용거래정보: 보험계약정보(상품종류,기간,보험가입금액 등), 보험금정보(보험금지급사유,지급금액 등)</li>
              <li style={{paddingTop: 10}}>※ 업무위탁을 목적으로 개인(신용)정보를 처리하는 경우 별도의 동의 없이 업무 수탁자에게 귀하의 정보를 제공할 수 있습니다. </li>
           </ul>
          </section>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

const styles = {
  container: {
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '10px',
    marginBottom: '10px',
  },
  list: {
    paddingLeft: '20px',
    marginBottom: '16px',
    lineHeight: '1.6',
    fontSize: '14px',
    color: '#666',
  },
  note: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '16px',
  },
};


export default CreditProvision;
