import React, { useEffect } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
  FormTextarea,
} from '../../components/form-control/formControl';
import * as yup from 'yup';
import { FormikTouched, useFormik } from 'formik';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { COMPANY_INSURANCE_STEP2 } from '../../utils/routers';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';
import { CompanyInsuranceStep1ValuesType } from '../../types/insurance/company-insurance';

const validationSchema = yup.object({
  reName: yup
    .string()
    .trim()
    .required('성명을 입력해주세요.'),
  rePhone: yup
    .number()
    .typeError('숫자만 입력할 수 있습니다.')
    .required('휴대폰 번호를 입력해주세요.'),
  email: yup
    .string()
    .email('정상적인 이메일이 아닙니다.')
    .required('이메일을 입력해주세요.'),
  accidentDay: yup
    .string()
    .test('is-valid-date', '사고일시를 입력해주세요.', value => {
      if (!value || value === ' 00:00') return false;
      return true;
    })
    .required('사고일시를 선택해주세요.'),
  workplace: yup
    .string()
    .trim()
    .required('사업장명을 입력해주세요.'),
  accidentLocation: yup
    .string()
    .required('사고장소를 입력해주세요.')
    .trim()
    .min(2, '사고장소를 최소 2글자 이상 입력해주세요.')
    .max(30, '사고장소는 최대 30자까지 입력할 수 있습니다.'),
  accidentDetails: yup
    .string()
    .required('사고경위를 입력해주세요.')
    .trim()
    .min(2, '사고경위를 최소 2글자 이상 입력해주세요.')
    .max(100, '사고경위는 최대 100자까지 입력할 수 있습니다.'),
});

function CompanyInsuranceSetp1() {
  const { goTo } = useNavigateTo();
  const { step1Values, setStep1Values } = useCompanyInsuranceFunnel();
  const formik = useFormik({
    initialValues:  { ...step1Values },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log('Form submitted:', values);
    },
  });

  const handleNext = async () => {
    formik.setTouched(
      Object.keys(formik.values).reduce<Record<string, boolean>>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {}) as FormikTouched<CompanyInsuranceStep1ValuesType>
    );
    const isValid = await validFormik(formik);
    if (isValid) {
      const values = formik.values as CompanyInsuranceStep1ValuesType;
      // console.log('values',values);
      setStep1Values(values);
      goTo(COMPANY_INSURANCE_STEP2);
    }
  };

  useEffect(() => {
    setStep1Values(formik.values as CompanyInsuranceStep1ValuesType);
  }, [formik.values]);
  return (

    <div className='wrap'>
      <DetailHeader title='기업보험' />
      <div className='container'>
        <div className='guide gray_bortop pb_28'>
          <div className='fs_18 fw_600 mt_28'>1. 접수자 인적사항</div>
          <FormControl label='접수자 성명' className='mt_20' required hasError={!!formik.errors.reName && !!formik.touched.reName}>
            <FormInput formik={formik} name='reName' placeholder='접수자 성명을 입력' />
          </FormControl>
          <FormControl
            label='접수자 휴대폰번호'
            className='mt_16'
            required
            hasError={!!formik.errors.rePhone && !!formik.touched.rePhone}
          >
            <FormInput formik={formik} name='rePhone' placeholder='`-`없이 숫자만 입력' maxLength={11}/>
          </FormControl>
          <FormControl
            label='접수자 이메일'
            className='mt_16'
            required
            hasError={!!formik.errors.email && !!formik.touched.email}
          >
            <FormInput formik={formik} name='email' placeholder='접수자 이메일을 입력'/>
          </FormControl>
          <div className='fs_18 fw_600 mt_28'>2. 사고유형</div>
          <FormControl
            label='사고일시'
            className='mt_16'
            hasError={!!formik.errors.accidentDay && !!formik.touched.accidentDay}
            required
          >
            <FormDatePicker
              selectedDate={formik.values.accidentDay?.split(' ')[0] || ''}
              onDateChange={e => {
                formik.setFieldValue(
                  'accidentDay',
                  `${e} ${formik.values.accidentDay?.split(' ')[1] || '00:00'}`
                );
              }}
              selectedTime={formik.values.accidentDay?.split(' ')[1] || ''}
              onTimeChange={e => {
                formik.setFieldValue(
                  'accidentDay',
                  `${formik.values.accidentDay?.split(' ')[0] || ''} ${e}`
                );
              }}
              hasError={!!formik.errors.accidentDay && !!formik.touched.accidentDay}
              enableTimePicker
            />
            {formik.errors.accidentDay && formik.touched.accidentDay && (
              <FormErrorMessage>{String(formik.errors.accidentDay)}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            label='사업자 명'
            className='mt_16'
            required
            hasError={!!formik.errors.workplace && !!formik.touched.workplace}
          >
            <FormInput formik={formik} name='workplace' placeholder='사업장명 입력' />
          </FormControl>
          <FormControl
            label='사고장소 주소'
            className='mt_16'
            required
            hasError={!!formik.errors.accidentLocation && !!formik.touched.accidentLocation}
          >
            <FormInput formik={formik} name='accidentLocation' placeholder='사고장소 주소 입력' />
          </FormControl>
          <FormControl
            label='사고경위'
            className='mt_16'
            required
            hasError={!!formik.errors.accidentDetails && !!formik.touched.accidentDetails}
          >
            <FormTextarea formik={formik} name='accidentDetails' placeholder='육하원칙에 따라 간략히 기재해 주세요' />
          </FormControl>
        </div>
      </div>
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={handleNext}>
            다음
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyInsuranceSetp1;
