import React, { useEffect } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
  FormTextarea,
} from '../../components/form-control/formControl';
import * as yup from 'yup';
import { FormikTouched, useFormik } from 'formik';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { TERMS_LIST } from '../../components/Terms';
import { COMPANY_INSURANCE_STEP3 } from '../../utils/routers';
import { getDamageType } from '../../utils/claimHistory';
import Postcode from '../../components/DaumPostCode';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';
import {
  CompanyInsuranceStep2ValuesType,
} from '../../types/insurance/company-insurance';

const validationSchema = yup.object({
  damageType: yup.number().required('손해유형을 선택해주세요.'),
  damageSummary: yup.string().when('damageType', {
    is: 0,
    then: schema => schema
      .required('개요를 입력해주세요.')
      .min(2, '사고장소를 최소 2글자 이상 입력해주세요.')
      .max(50, '사고장소는 최대 100자까지 입력할 수 있습니다.'),
    otherwise: schema => schema.optional(),
  }),
  expectedDamageAmount: yup.string().when('damageType', {
    is: 0,
    then: schema => schema.required('손해액을 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  etcNote: yup.string().when('damageType', {
    is: 0,
    then: schema => schema
      .required('요청사항을 입력해주세요.')
      .min(2, '사고장소를 최소 2글자 이상 입력해주세요.')
      .max(50, '사고장소는 최대 50자까지 입력할 수 있습니다.'),
    otherwise: schema => schema.optional(),
  }),
  victimName: yup.string().when('damageType', {
    is: 1,
    then: schema => schema
      .required('성명을 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  birth: yup.number().when('damageType', {
    is: 1,
    then: schema => schema
      .typeError('숫자만 입력할 수 있습니다.')
      .required('생년월일을 입력해주세요.')
      .min(6, '6자리를 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  victimPhone: yup.number().when('damageType', {
    is: 1,
    then: schema => schema
      .typeError('숫자만 입력할 수 있습니다.')
      .required('휴대폰 번호를 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  address: yup.string().when('damageType', {
    is: 1,
    then: schema => schema
      .required('주소를 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  addressDetail: yup.string().when('damageType', {
    is: 1,
    then: schema => schema
      .required('주소를 입력해주세요.'),
    otherwise: schema => schema.optional(),
  }),
  injuries: yup.string().when('damageType', {
    is: 1,
    then: schema => schema
      .required('상해/부상을 입력해주세요.')
      .min(2, '상해/부상을 최소 2글자 이상 입력해주세요.')
      .max(50, '상해/부상은 최대 50자까지 입력할 수 있습니다.'),
    otherwise: schema => schema.optional(),
  }),
  victimNote: yup.string().when('damageType', {
    is: 1,
    then: schema => schema
      .required('요청사항을 입력해주세요.')
      .min(2, '요청사항을 최소 2글자 이상 입력해주세요.')
      .max(50, '요청사항은 최대 50자까지 입력할 수 있습니다.'),
    otherwise: schema => schema.optional(),
  }),

});
function CompanyInsuranceSetp2() {
  const { goTo } = useNavigateTo();
  const { step2Values, setStep2Values } = useCompanyInsuranceFunnel();
  const formik = useFormik({
    initialValues:  { ...step2Values },
    validationSchema: validationSchema,
    onSubmit: values => {},
  });
  const handleCompleteAddress = (data: string) => {
    formik.setFieldValue('address', data);
  };
  const handleNext = async () => {
    formik.setTouched(
      Object.keys(formik.values).reduce<Record<string, boolean>>((acc, key) => {
        acc[key] = true;
        return acc;
      }, {}) as FormikTouched<CompanyInsuranceStep2ValuesType>
    );
    const isValid = await validFormik(formik);
    if (isValid) {
      const values = formik.values as CompanyInsuranceStep2ValuesType;
      setStep2Values(values);
      console.log('formik',values);
      goTo(COMPANY_INSURANCE_STEP3);
    }
    // setIsDirty(true);
    // formik.setFieldTouched('gender', true);
    // const isValid = (await validFormik(formik)) && checkedList.length === TERMS_LIST.length;
    // if (!isValid) return;
    // const hasErrors = Object.keys(formik.errors).length > 0;
    // setStep1Values({
    //   ...step1Values,
    //   isAgree: checkedList.length === TERMS_LIST.length,
    // });
    //
    // if (checkSubscriptionDays?.message) return;
    //
    // if (isValid && !hasErrors) {
    //   goTo(COMPANY_INSURANCE_STEP3);
    // }
  };

  useEffect(() => {
    setStep2Values(formik.values as CompanyInsuranceStep2ValuesType);
  }, [formik.values]);
  // console.log('e2e',formik.values);
  return (
    <div className='wrap'>
      <DetailHeader title='기업보험' cancel={true} />
      <div className='container'>
        <div className='guide gray_bortop pb_28'>
          <div className='fs_18 fw_600 mt_28'>3. 손해 사항</div>
          <FormControl
            label='손해 유형'
            className='mt_20'
            required
            hasError={!!formik.errors.damageType && !!formik.touched.damageType}
          >
            <div className='dis_flex gap12'>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad01'
                  className='hide'
                  checked={formik.values.damageType === 0}
                  onChange={() => formik.setFieldValue('damageType', 0)}
                />
                <label htmlFor='rad01' className='btn gray02'>
                  재물
                </label>
              </div>
              <div className='flex1'>
                <input
                  type='radio'
                  name='rad00'
                  id='rad02'
                  className='hide'
                  checked={formik.values.damageType === 1}
                  onChange={() => formik.setFieldValue('damageType', 1)}
                />
                <label htmlFor='rad02' className='btn gray02'>
                  배상책임
                </label>
              </div>
            </div>
          </FormControl>
          {formik.errors.damageType && formik.touched.damageType && (
            <div className='state_txt_error'>{String(formik.errors.damageType)}</div>
          )}
          {formik.values.damageType == 0 && (
          <>
            <FormControl
              label='손해사항 개요'
              className='mt_20'
              hasError={!!formik.errors.damageSummary && !!formik.touched.damageSummary}
              required
            >
              <FormInput formik={formik} name='damageSummary' placeholder='구체적인 손해사항 기재해 주세요' />
            </FormControl>
            <FormControl
              label='예상 손해액'
              className='mt_20'
              hasError={!!formik.errors.expectedDamageAmount && !!formik.touched.expectedDamageAmount}
              required
            >
              <FormInput formik={formik} name='expectedDamageAmount' placeholder='손해액 입력' />
            </FormControl>
            <FormControl
              label='기타 요청사항'
              className='mt_20'
              hasError={!!formik.errors.etcNote && !!formik.touched.etcNote}
              required
            >
              <FormInput formik={formik} name='etcNote' placeholder='요청사항 입력' />
            </FormControl>
          </>
          )}
          {formik.values.damageType == 1 && (
            <>
              <FormControl
                label='피해자 성명'
                className='mt_20'
                hasError={!!formik.errors.victimName && !!formik.touched.victimName}
                required
              >
                <FormInput formik={formik} name='victimName' placeholder='피해자 성명을 입력' />
              </FormControl>
              <FormControl
                label='대리인'
                className='mt_20'
                // hasError={!!formik.errors.agent && !!formik.touched.agent}
                // required
              >
                <FormInput formik={formik} name='deputy' placeholder='피해자 대리인 성명을 입력' />
              </FormControl>
              <FormControl
                label='관계'
                className='mt_20'
                // hasError={!!formik.errors.relationship && !!formik.touched.relationship}
                // required
              >
                <FormInput formik={formik} name='relationship' placeholder='관계를 입력' />
              </FormControl>
              <FormControl
                label='생년월일'
                className='mt_20'
                hasError={!!formik.errors.birth && !!formik.touched.birth}
                required
              >
                <FormInput formik={formik} name='birth' placeholder='피해자 생년월일 입력' maxLength={6}/>
              </FormControl>
              <FormControl
                label='휴대폰번호'
                className='mt_16'
                required
                hasError={!!formik.errors.victimPhone && !!formik.touched.victimPhone}
              >
                <FormInput formik={formik} name='victimPhone' placeholder='피해자 또는 대리인 휴대폰번호' maxLength={11}/>
              </FormControl>
              <FormControl
                label='현재 거주지 주소'
                required
                hasError={!!formik.touched.addressDetail && !!formik.errors.addressDetail}
              >
                <div className='dis_flex gap8'>
                  <div className='inp flex1'>
                    <input
                      type='text'
                      placeholder='기본주소'
                      value={formik.values.address}
                      readOnly
                    />
                  </div>
                  <Postcode callback={handleCompleteAddress} />
                </div>
                {formik.errors.address && formik.touched.address && (
                  <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                )}
                <FormInput formik={formik} name='addressDetail' placeholder='상세주소 입력' />
              </FormControl>
              <FormControl
                label='상해/부상 내용'
                className='mt_20'
                hasError={!!formik.errors.injuries && !!formik.touched.injuries}
                required
              >
                <FormInput formik={formik} name='injuries' placeholder='상해/부상 내용 입력' />
              </FormControl>
              <FormControl
                label='피해자 요청사항'
                className='mt_20'
                hasError={!!formik.errors.victimNote && !!formik.touched.victimNote}
                required
              >
                <FormInput formik={formik} name='victimNote' placeholder='피해자 요청사항을 입력' />
              </FormControl>
            </>
          )}
        </div>
      </div>
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={handleNext}>
            다음
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyInsuranceSetp2;
