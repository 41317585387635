import React, { useRef, useState } from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import useNavigateTo from '../../hooks/useNavigateTo';
import useBottomSheet from '../../hooks/useBottomSheet';
import useIsNativeWebView from '../../hooks/useIsNativeWebView';
import DocumentsSheet from '../../components/common/DocumentsSheet';
import useMessageHandler, { FileData } from '../../hooks/useMessageHandler';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';
import { useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import { base64ToFile, debugFormData } from '../../utils/file';
import AlertModal from '../../components/modal/AlertModal';
import TooltipContent from '../../components/groupAccidentInsurance/TooltipContent';
import CompanyInsuranceStep4 from './CompanyInsuranceStep4';
import { Simulate } from 'react-dom/test-utils';
import error = Simulate.error;

interface CreateCompanyInsuranceResponse {
  status: boolean;
  data: {
    insuranceClaimId: number;
  };
}

function CompanyInsuranceStep5() {
  const { goTo } = useNavigateTo();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const isNative = useIsNativeWebView();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachedDocumentError, setAttachedDocumentError] = useState('');
  const [successModalDesc, setSuccessModalDesc] = useState('');

  const { finalValues, clearForm } = useCompanyInsuranceFunnel();
  console.log('finalValues',finalValues);
  const { files, setFiles, addFile } = useMessageHandler(() => toggleBottomSheet(false));
  // console.log('finalValues',finalValues.Name)
  const createCompanyInsuranceMutation =
    useCustomMutation<CreateCompanyInsuranceResponse>(
      endpointConfig.insuranceCompany,
      'post',
      1
    );

  const createAttachedDocumentsMutation =
    useCustomMutation<CreateCompanyInsuranceResponse>(
      endpointConfig.createAttachedDocument,
      'post',
      0
    );

  const createFormData = () => {
    const formData = new FormData();
    formData.append('accessCode', finalValues.accessCode);
    formData.append('receiptName', finalValues.reName);
    formData.append('receiptPhone', finalValues.rePhone);
    formData.append('email', finalValues.email);
    formData.append('workplace', finalValues.workplace);
    formData.append('accidentDay', finalValues.accidentDay);
    formData.append('accidentDetails', finalValues.accidentDetails);
    formData.append('accidentLocation', finalValues.accidentLocation);
    formData.append('damageType', finalValues.damageType.toString());
    // 손해유형 재물 일 경우만 추가
    if (finalValues.damageType !== 1) {
      formData.append('damageSummary', finalValues?.damageSummary || '');
      formData.append('expectedDamageAmount', finalValues.expectedDamageAmount || '');
      formData.append('etcNote', finalValues.etcNote || '');
    }else{
      formData.append('address', finalValues?.address || '');
      formData.append('addressDetail', finalValues.addressDetail || '');
      formData.append('birth', finalValues.birth || '');
      formData.append('victimPhone', finalValues?.victimPhone || '');
      formData.append('victimName', finalValues.victimName || '');
      formData.append('deputy', finalValues.deputy || '');
      formData.append('victimNote', finalValues.victimNote || '');
      formData.append('injuries',finalValues.injuries || '');
      formData.append('relationship',finalValues.relationship || '');
    }
    formData.append('claimantName', finalValues.claimantName);
    formData.append('claimantSignature', finalValues.claimantName);
    if (finalValues.claimantSignaturePreview) {
      formData.append(
        'claimantSignature',
        base64ToFile(finalValues.claimantSignaturePreview, 'claimantSignature', 'image/png')
      );
    }
    console.log('rrrr',finalValues);

    return formData;
  };

  const createAttachedDocumentsFormData = (insuranceClaimId: number, fileIndex: number) => {
    const formData = new FormData();
    const fileLength = files.length;
    console.log('files :', JSON.stringify(files));

    formData.append('insuranceClaimId', insuranceClaimId.toString());
    const file = files[fileIndex].originalFile;
    console.log(files[fileIndex].originalFile);
    if (!file) return;

    formData.append('documentaryEvidence', file);
    formData.append('lastYn', (fileLength === fileIndex + 1).toString());
    return formData;
  };

  const postAttachedDocuments = async (insuranceClaimId: number) => {
    let isSuccess = true;
    files.forEach((_, idx) => {
      const attachedDocumentsFormData = createAttachedDocumentsFormData(insuranceClaimId, idx);
      debugFormData(attachedDocumentsFormData as FormData);
      createAttachedDocumentsMutation.mutateAsync(attachedDocumentsFormData, {
        onSuccess: response => {
          if (!response.status) {
            isSuccess = false;
          }
        },
      });
    });
    return isSuccess;
  };

  const onSubmit = () => {

    if (files.length === 0) {
      setAttachedDocumentError('증빙서류를 첨부해 주세요');
      return;
    }
    const formData = createFormData();
    console.log('formData',formData);
    // console.log('📌 FormData 내용:');

    createCompanyInsuranceMutation.mutate(formData, {
      onSuccess: async response => {
        const { insuranceClaimId } = response.data;
        const isSuccess = await postAttachedDocuments(insuranceClaimId);
        console.log('isSuccess :', isSuccess);
        if (isSuccess) {
          setSuccessModalDesc('보험금 청구 접수를 성공적으로\n완료하였습니다');
          clearForm();
        }
      },
      onError: (error) => {
        console.error("보험금 청구 실패:", error);
      },
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      for (const file of fileArray) {
        try {
          await addFile(file); // 성공적으로 추가된 파일만 처리
        } catch (error) {
          console.error('파일 추가 중 오류:', error); // 오류를 명시적으로 처리
        }
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles(prevFiles => prevFiles.filter((_, idx) => idx !== index));
  };

  const onCloseSuccessModal = () => {
    clearForm();
    goTo('/');
    setSuccessModalDesc('');
  };

  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='기업보험' cancel={true} />
        {/* <!-- // header --> */}

        {/* <!-- container --> */}
        <div className='container'>
          <div className='guide'>
            <div className='fs_18 fw_600 mt_14'>6. 증빙서류 첨부</div>
            <p className='mt_14 fs_14 col_gray'>사용하실 증빙 서류 이미지를 추가해 주세요.</p>
            <div className='mt_16'>
              <button
                className='btn blue_border'
                onClick={() => {
                  if (isNative) toggleBottomSheet(true);
                  else fileInputRef.current?.click();
                }}
              >
                추가하기
              </button>
            </div>

            {files.length > 0 && (
              <div className='inner_gray_box square mt_18'>
                {files.map((file, idx) => (
                  <FileList file={file} handleRemoveFile={() => handleRemoveFile(idx)} />
                ))}
              </div>
            )}
          </div>
        </div>
        {/* <!-- // container --> */}

        {/* <!-- foot_container --> */}
        <div className='foot_container'>
          <div className='foot_btn'>
            <button
              className={`btn blue`}
              onClick={onSubmit}
              // onclick="popOpen('#alert01')"
              disabled={createAttachedDocumentsMutation.isLoading ||
                createCompanyInsuranceMutation.isLoading}
            >
              {createAttachedDocumentsMutation.isLoading ||
              createCompanyInsuranceMutation.isLoading
                ? '처리중'
                : '완료'}
            </button>
          </div>
        </div>
        {/* <!-- // foot_container --> */}
      </div>

      {/* <!-- bottom_sheet_pop --> */}
      {/* input 요소는 보이지 않게 설정 */}
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {isNative && (
        <DocumentsSheet bottomSheetRef={bottomSheetRef} toggleBottomSheet={toggleBottomSheet} />
      )}

      {/* <!-- alert_pop --> */}
      <AlertModal
        title='증빙서류 첨부'
        desc={attachedDocumentError}
        onClose={() => setAttachedDocumentError('')}
        sx={{ display: attachedDocumentError ? 'block' : 'none' }}
      />

      {/* <!-- alert_pop --> */}
      <SuccessModal
        title='접수 완료'
        desc={successModalDesc}
        onClose={onCloseSuccessModal}
        sx={{ display: successModalDesc ? 'block' : 'none' }}
      />
    </>
  );
}

export default CompanyInsuranceStep5;

interface FileListProps {
  file: FileData;
  handleRemoveFile: () => void;
}

const FileList = ({ file, handleRemoveFile }: FileListProps) => {
  return (
    <div className="txt_type02">
      {file.name}
      <i className="x_icon_02" onClick={handleRemoveFile}></i>
    </div>
  );
};

interface SuccessModalProps {
  title: string;
  desc: string;
  onClose: () => void;
  sx: any;
}

const SuccessModal = ({ title, desc, onClose, sx }: SuccessModalProps) => {
  return (
    <div className='alert_pop' id='alert02' style={sx}>
      <div className='pop_wrap'>
        <div className='pop_tit'>{title}</div>
        <div className='pop_body'>
          <div className='msg'>{desc}</div>
        </div>
        <div className='pop_btn'>
          <button className='btn black' onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

