import React, { useEffect, useMemo, useState } from 'react';
import { CLAIM_ACCIDENT_STEP4, COMPANY_INSURANCE_STEP4, DOMESTIC_TRAVEL_INSURANCE_STEP2 } from '../../utils/routers';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import * as yup from 'yup';
import {
  FormControl,
  FormDatePicker,
  FormErrorMessage,
  FormInput,
} from '../../components/form-control/formControl';
import useDomesticTravelFunnel from '../../hooks/useDomesticTravelFunnel';
import CreditTerms, { TERMS_LIST } from '../../components/CreditTerms';
import { useFormik } from 'formik';
import { DomesticTravelStep1ValuesType } from '../../types/insurance/domestic-travle';
import { formatSsnFirst } from '../../utils/format';
import { setFormikTouched, validFormik } from '../../utils/formik';
import { DATE_SCHEMA } from '../../constants/validationSchemas';
import { SUBSCRIPTION_DAYS_LIST_DOMESTIC } from '../../constants/subscriptionDaysList';
import dayjs from 'dayjs';
import { checkTravelPeriodWithRecommendation } from '../../utils/checkMonth';
import { formatKoreanDate } from '../../utils/date';
import SignInput from '../../components/groupAccidentInsurance/SignInput';
import SignSheet from '../../components/groupAccidentInsurance/SignSheet';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';
import useBottomSheet from '../../hooks/useBottomSheet';
import {
  CompanyInsuranceStep3ValuesType,
} from '../../types/insurance/company-insurance';
import { Step2ValuesType } from '../../types/insurance/group-accident';

const validationSchema = yup.object().shape({
  collectionStatus: yup
    .boolean()
    .isTrue('서비스 신청에 동의해주세요.')
    .required('서비스 신청에 동의해주세요.'),
  provisionStatus: yup
    .boolean()
    .isTrue('서비스 신청에 동의해주세요.')
    .required('서비스 신청에 동의해주세요.'),
  inquiryStatus: yup
    .boolean()
    .isTrue('서비스 신청에 동의해주세요.')
    .required('서비스 신청에 동의해주세요.'),
  claimantName: yup.string().required('서명을 해주세요.'),
});

function CompanyInsuranceStep3() {
  const { goTo } = useNavigateTo();
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const { toggleBottomSheet, bottomSheetRef } = useBottomSheet();
  const [isDirty, setIsDirty] = useState(false);
  const { step3Values, setStep3Values } = useCompanyInsuranceFunnel();
  const formik = useFormik({
    initialValues: { ...step3Values },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });
  // console.log('step3Values',step3Values);
  const handleCheck = (key: string) => {
    console.log('r2',key)
    if (checkedList.includes(key)) {
      setCheckedList(prev => {
        const newCheckedList = prev.includes(key) ? prev.filter(item => item !== key) : [...prev, key];
        formik.setFieldValue(`${key}Status`, newCheckedList.includes(key));
        return newCheckedList;
      });
    } else {
      setCheckedList(prev => [...prev, key]);
    }
  };
  const handleAllCheck = () => {
    const isAllChecked = checkedList.length === TERMS_LIST.length;
    const newCheckedList = isAllChecked ? [] : TERMS_LIST.map(item => item.key);

    setCheckedList(newCheckedList);

    // ✅ Formik 값 일괄 업데이트
    formik.setFieldValue('collectionStatus', newCheckedList.includes('collection'));
    formik.setFieldValue('provisionStatus', newCheckedList.includes('provision'));
    formik.setFieldValue('inquiryStatus', newCheckedList.includes('inquiry'));
  };

  const [claimantSignaturePreview, setClaimantSignaturePreview] = useState<string | undefined>(
    step3Values?.claimantSignaturePreview
  );

  const onSaveSignature = async (url: string) => {
    setClaimantSignaturePreview(url);
    formik.setFieldValue('claimantSignaturePreview', url);
  };

  const onClickNext = async () => {
    console.log('formik', checkedList);
    setFormikTouched(formik);
    setIsDirty(true);

    formik.setFieldValue('collectionStatus', checkedList.includes('collection'));
    formik.setFieldValue('provisionStatus', checkedList.includes('provision'));
    formik.setFieldValue('inquiryStatus', checkedList.includes('inquiry'));
    formik.setFieldTouched('claimantSignaturePreview', true);
    const isValid = await validFormik(formik);
    if (!isValid) return;
    const values = formik.values as CompanyInsuranceStep3ValuesType;
    setStep3Values(values);
    goTo(COMPANY_INSURANCE_STEP4);
  };

  useEffect(() => {
    setStep3Values({
      ...formik.values,
      collectionStatus: checkedList.includes('collection'),
      provisionStatus: checkedList.includes('provision'),
      inquiryStatus: checkedList.includes('inquiry'),
    } as CompanyInsuranceStep3ValuesType);
  }, [formik.values, checkedList]);

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader title='기업보험' cancel={true} />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      <div className='container'>
        <div className='guide gray_bortop'>
          <div className='fs_18 fw_600 mt_28'>4. 보험금 청구를 위한 상세 동의</div>
          <CreditTerms
            checkedList={checkedList}
            handleCheck={handleCheck}
            handleAllCheck={handleAllCheck}
          />
          {isDirty && checkedList.length !== TERMS_LIST.length && (
            <div className='state_txt_error'>약관에 동의해주세요.</div>
          )}
          <div className='fs_18 fw_600 mt_32'>청구일: {formatKoreanDate(new Date())}</div>
          <div className='inp_wrap mt_20'>
            <FormControl
              label='청구자 성명'
              className='mt_20'
              hasError={!!formik.errors.claimantName && !!formik.touched.claimantName}
              required
            >
              <FormInput formik={formik} name='claimantName' placeholder='청구자 성명 입력' />
            </FormControl>
          </div>

          <SignInput
            signaturePreview={claimantSignaturePreview}
            toggleBottomSheet={toggleBottomSheet}
          />
          {formik.errors.claimantSignaturePreview && formik.touched.claimantSignaturePreview && (
            <div className='state_txt_error'>{String(formik.errors.claimantSignaturePreview)}</div>
          )}
        </div>
      </div>
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn blue' onClick={onClickNext}>
            다음
          </button>
        </div>
      </div>

      <SignSheet
        showSignSheet={true}
        onSave={onSaveSignature}
        toggleBottomSheet={toggleBottomSheet}
        bottomSheetRef={bottomSheetRef}
      />
    </div>
  );
}

export default CompanyInsuranceStep3;
