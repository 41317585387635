import React from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import DetailHeader from '../../components/common/DetailHeader';
import { FormControl, FormInput } from '../../components/form-control/formControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { setFormikTouched, validFormik } from '../../utils/formik';
import {
  CompanyInsuranceCodeValuesType,
} from '../../types/insurance/company-insurance';
import { TERMS_LIST } from '../../components/Terms';
import { COMPANY_INSURANCE_STEP1 } from '../../utils/routers';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';
import { useCustomMutation } from '../../api/apiHooks';
import endpointConfig from '../../config/endpoint';
import Cautioninfo from '../../components/Cautioninfo';
import useBottomSheet from '../../hooks/useBottomSheet';
// const validCodes = ['123456', 'XYZ789'];
const validationSchema = yup.object({
  accessCode: yup
    .string()
    .trim()
    .required('엑세스 코드를 입력해주세요.')
    .min(6, '엑세스 코드는 6자리를 입력해야 합니다.')
    // .test('match-code', '엑세스 코드가 올바르지 않습니다.', value => validCodes.includes(value)),
});

interface CreateCompanyInsuranceCodResponse {
  accessCode: string
}
function CompanyInsuranceCode() {
  const { goTo } = useNavigateTo();
  const { codeValues, setCodeValues } = useCompanyInsuranceFunnel();
  const { bottomSheetRef, toggleBottomSheet } = useBottomSheet();
  const formik = useFormik({
    initialValues: codeValues as CompanyInsuranceCodeValuesType,
    validationSchema: validationSchema,
    onSubmit: values => {},
  });

  const createCompanyInsuranceCodeMutation =
    useCustomMutation<CreateCompanyInsuranceCodResponse>(
      endpointConfig.accessCodeCheck,
      'post',
      0
    );

  const handleNext = async () => {
    const isValid = await validFormik(formik);

    setCodeValues({
      ...codeValues,
    });

    if (isValid) {
      const values = formik.values as CompanyInsuranceCodeValuesType;
      const formData = {
        accessCode: values?.accessCode,
      }
      createCompanyInsuranceCodeMutation.mutate(formData, {
        onSuccess: async response => {
          setCodeValues(values);
          goTo(COMPANY_INSURANCE_STEP1);
        },
        onError: error => {
          formik.setErrors({ accessCode: '엑세스 코드가 올바르지 않습니다.' });
          console.log('error',error);
        },
      });
      // console.log('values',formData);
      // goTo(COMPANY_INSURANCE_STEP1);
    }
  };

  return (
    <div className='wrap'>
      <DetailHeader title='기업보험'/>
      <div className='container'>
        <div className="guide pb_28 mt_20">
          <p>정보 확인을 위해 발급된 엑세스 코드를 입력해주세요.</p>
          <FormControl label="엑세스 코드" required hasError={!!formik.errors.accessCode && !!formik.touched.accessCode}>
            <FormInput
              formik={formik}
              name="accessCode"
              placeholder="코드 입력"
              maxLength={6}
              value={formik.values.accessCode}
            />
          </FormControl>
        </div>
      </div>

      <div className='foot_container'>
        <div className='foot_btn'>
           <button className={`btn blue ${formik.values.accessCode ? '' : 'disabled'}`} onClick={handleNext}>
            조회
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyInsuranceCode;
