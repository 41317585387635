import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';

function CreditCollection() {
  return (
    <>
      <div className='wrap'>
        {/* <!-- header --> */}
        <DetailHeader title='개인(신용)정보 수집 이용 동의' terms={true} />

        {/* <!-- container --> */}
        <div style={styles.container}>
          <h1 style={styles.sectionTitle}>[필수] 보험금청구를 위한 상세동의서</h1>

          <p style={styles.note}>
            귀하는 개인(신용)정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의를 철회할 수 있습니다.
            다만, 본 동의는 ‘보험금 청구’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무 수행이 불가능 합니다.
          </p>

          {/* 수집·이용에 관한 사항 */}
          <section>
            <h2 style={styles.sectionTitle}>1. 수집·이용에 관한 사항</h2>

            {/* 수집·이용 목적 */}
            <h3 style={styles.sectionTitle}>• 수집·이용 목적</h3>
            <ul style={styles.list}>
              <li>① 보험사고·보험사기 조사 및 보험금 지급·심사(손해사정 또는 의료자문 포함)</li>
              <li>② 보험금청구서류 접수대행 서비스 및 교통사고 처리내역 발급 간소화 서비스</li>
              <li>③ 민원처리 및 분쟁대응, 금융거래 관련 업무</li>
              <li>④ 자동차과실비율분쟁심의업무(자동차보험에 한함)</li>
              <li>⑤ 자동차 사고 연계처리를 위한 본인의 보험계약(장기, 일반보험)에 대한 보험금 지급업무 포함</li>
              <li>⑥ 「신용정보의 이용 및 보호에 관한 법률」상 의무 이행</li>
            </ul>

            {/* 보유 및 이용기간 */}
            <p style={styles.note}>
              • 보유 및 이용기간: 동의일로부터 거래 종료 후 5년까지 (단, 다른 관련법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
            </p>
            <p style={styles.note}>
              ※ 위 보유기간에서의 거래 종료일이란 “① 보험계약 만기, 해지, 취소, 철회일 또는 소멸일 ② 보험금 청구권 소멸시효 완성일 ③ 채권·채무 관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한 날”을 말합니다.
            </p>

            {/* 수집·이용 항목 */}
            <h3 style={styles.sectionTitle}>• 수집·이용 항목</h3>
            <ul style={styles.list}>
              <li>
                ① 고유식별정보: 주민등록번호, 외국인등록번호, 여권번호, 운전면허번호
              </li>
              <li>
                ② 민감정보: 피보험자의 질병·상해에 관한 정보(진료기록, 상병명 등), 보험사고 조사(보험사기 포함) 및 손해사정 업무 수행과 관련하여 취득한 정보
              </li>
              <li>
                ③ 개인(신용)정보:
                <ul style={styles.list}>
                  <li>-일반개인정보: 성명, 본인확인정보(CI, DI), 주소, 성별, 직업, 유무선 전화번호, 이메일 등</li>
                  <li>-신용거래정보: 금융거래 업무 관련 정보(보험금 지급계좌 등), 보험계약정보(상품종류, 기간, 보험가입금액 등), 보험금정보(보험금 지급사유, 지급금액 등)</li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
        {/* <!-- // container --> */}
      </div>
    </>
  );
}

const styles = {
  container: {
    margin: "0 auto",
    height: '100%',
    backgroundColor: '#fff',
    padding: "20px",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "10px",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "16px",
    lineHeight: "1.6",
    fontSize: "14px",
    color: "#666",
  },
  note: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "16px",
  },
};

export default CreditCollection;
