import React from 'react';
import DetailHeader from '../../components/common/DetailHeader';
import { PET } from '../../utils/routers';
import Tab from '../../components/pet/Tab';
import useCurrentPath from '../../hooks/useCurrentPath';

function PetDetail() {
  const pathname = useCurrentPath();
  const isHipetPage = pathname.includes('hipet');

  /* -------------------------------------------------------------------------- */
  /*                                   handler                                  */
  /* -------------------------------------------------------------------------- */
  const handleDownload = () => {
    const filePath = '/document/[현대해상] 펫보험 청구서류 안내_.pdf';
    const link = document.createElement('a');
    link.href = filePath;
    link.download = '[현대해상] 펫보험 청구서류 안내_.pdf'; // 다운로드 파일 이름
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='wrap'>
      {/* <!-- header --> */}
      <DetailHeader
        title={isHipetPage ? '하이펫 애견보험' : '굿앤굿 우리펫보험'}
        path={PET}
        hasHomeBtn={true}
        tab={<Tab />}
      />
      {/* <!-- // header --> */}

      {/* <!-- container --> */}
      {isHipetPage ? (
        <div className='container pet gray02'>
          <div className='guide'>
            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>01</div>
              <div className='fs_20 fw_600 mt_20'>
                반려견 질병, 상해 치료비는
                <br />
                물론 배상책임과 반려견 사망과
                <br />
                장례비까지 <span className='col_orange'>한번에 보장</span>해 드립니다!
              </div>
              <div className='fs_15 col_gray mt_12'>
                부담되는 반려동물 의료비
                <br />
                <span className='col_orange'>현대해상 하이펫 애견보험</span>
                으로 해결하세요.
              </div>
              <div className='mt_28'>
                <table className='type03'>
                  <colgroup>
                    <col width='25%' />
                    <col width='25%' />
                    <col width='25%' />
                    <col width='25%' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>의료비</th>
                      <th>장례비</th>
                      <th>배상책임</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        보상하는
                        <br />
                        손해
                      </th>
                      <td>한 사고/질병당 3백만원 한도 실손 보장</td>
                      <td>반려견 사망시 15만원 정액지급</td>
                      <td>대인,대물(반려견 한정) 1천만원 한도 실손보상</td>
                    </tr>
                    <tr>
                      <th>
                        자기
                        <br />
                        부담금
                      </th>
                      <td>1만원</td>
                      <td>없음</td>
                      <td>3만원</td>
                    </tr>
                    <tr>
                      <th>
                        연간
                        <br />
                        보상한도
                      </th>
                      <td>1천만원</td>
                      <td>15만원(1회)</td>
                      <td>무한</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className='mt_8 fs_12 col_gray txt_left'>
                주) 의료비중 피부질환은 보상하며 구강 내 질환(치과) 및 슬개관절 치료는 보상하지
                않습니다.
              </p>
            </div>

            <div className='txt_center mt_38'>
              <div className='orange_num_tag'>02</div>
              <div className='fs_20 fw_600 mt_20'>
                부담되는 <span className='col_orange'>의료비 적용</span> 항목
              </div>
              <div className='orange_chk_tag_list mt_28'>
                <p className='orange_chk_tag'>야간 진료비</p>
                <p className='orange_chk_tag'>통원 치료비</p>
                <p className='orange_chk_tag'>약값</p>
                <p className='orange_chk_tag'>진료비</p>
                <p className='orange_chk_tag'>검사비</p>
                <p className='orange_chk_tag'>치료비</p>
                <p className='orange_chk_tag'>주사비</p>
                <p className='orange_chk_tag'>입원</p>
                <p className='orange_chk_tag'>수술</p>
                <p className='orange_chk_tag'>응급실비</p>
              </div>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>03</div>
              <div className='fs_20 fw_600 mt_20'>
                <span className='col_orange'>하이펫 애견보험</span>을<br />
                가입해야만 하는 이유
              </div>
              <div className='inner_white_box type03 mt_28'>
                <p>
                  <span className='col_orange fw_600'>
                    질병 당 치료일 수와 치료 횟수에 관계없이
                  </span>{' '}
                  그 질병이 나을 때까지 자기부담금은 딱 1번 1만원만 공제한 나머지 금액의 70%를
                  하나의 질병 당 300만원까지 1년에 총 1000만원까지 보상해드립니다.
                </p>
                <p>
                  반려견이 타인이나 타인의 동물을 물거나 다치게 했을 때도{' '}
                  <span className='col_orange fw_600'>
                    자기부담금 3만 원을 공제한 나머지 100% 금액
                  </span>{' '}
                  을 1년에 1000만원까지 보상해드립니다.
                </p>
                <p>
                  반려견 사망 시 장례비 또한 <span className='col_orange fw_600'>15만원 보상</span>
                  해드립니다.
                </p>
              </div>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>04</div>
              <div className='fs_20 fw_600 mt_20'>
                하이펫 애견보험의
                <br />
                <span className='col_orange'>특별한 점</span>
              </div>
              <div className='mt_24'>
                <img src='/image/banner/pet_banner_04.png' alt='' />
              </div>
              <div className='mt_24'>
                <div className='inner_white_box type04'>
                  <p className='fs_16 fw_500'>
                    의료실비보험 제도와 유사한 개념을 적용하여
                    <br />
                    고객의 부담을 최소화
                  </p>
                </div>
                <div className='inner_white_box type04 mt_12'>
                  <p className='fs_16 fw_500'>
                    제 3자에 대한 법률상 배상책임을
                    <br />
                    부담하여 입은 손해를 보상
                  </p>
                  <p className='fs_14 fw_600 col_gray2 mt_8'>
                    (단, 제 3자의 재물 손해는 동물에 한함)
                  </p>
                </div>
                <div className='inner_white_box type04 mt_12'>
                  <p className='fs_16 fw_500'>간편한 보험 가입</p>
                </div>
                <div className='inner_white_box type04 mt_12'>
                  <p className='fs_16 fw_500'>
                    전국 모든 동물병원 및 대학동물병원에
                    <br />
                    상관없이 치료비를 보상
                  </p>
                </div>
                <div className='inner_white_box type04 mt_12'>
                  <p className='fs_16 fw_500'>반려견 사망 시 장례비까지 보상</p>
                </div>
              </div>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>05</div>
              <div className='fs_20 fw_600 mt_20'>가입 및 보상 절차</div>
              <div className='mt_12 fs_16 fw_500'>
                충분하게 치료부터 하신 후 현대해상 보상팀으로
                <br />
                <span className='col_orange'>팩스나 이메일 또는 카톡이나 문자</span>로<br />
                보내주시면 됩니다.
              </div>
              <div className='mt_36'>
                <div className='inner_white_box type05'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_01.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      병원에서 발행하는 진료내역서 및 영수증
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_02.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      보험금 청구서 양식 작성 및 정보동의서
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_03.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      피보험자 통장 사본 및<br />
                      본인 확인을 위한 신분증 사본
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_04.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      보험 가입 반려견 사진
                      <br />
                      (전면 사진, 측면 사진, 후면 사진)
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type06 mt_12'>
                  <div className='dis_flex gap16'>
                    <img src='/image/icon/printer_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>팩스 : 0505-988-5959</p>
                  </div>
                  <div className='dis_flex gap16 mt_8'>
                    <img src='/image/icon/mail_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>이메일 : 1070@sejongcas.com</p>
                  </div>
                  <div className='dis_flex gap16 mt_8'>
                    <img src='/image/icon/phone_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>핸드폰 : 010-8248-7015</p>
                  </div>
                </div>
                <div className='inner_white_box type07 mt_12'>
                  <div className='dis_flex column gap8'>
                    <img src='/image/icon/horn_orange_icon.png' alt='' className='w_36' />
                    <div className='fs_14 col_gray3'>
                      보험금 청구를 해보신 적이 있으셨다면{' '}
                      <span className='col_black fw_600'>
                        영수증, 내역서, 보험금 청구서 양식 3가지 서류만
                      </span>{' '}
                      팩스나 이메일 또는 카톡이나 문자로 접수하시면 됩니다.
                    </div>
                    <div className='col_orange fs_13 fw_600 underline'>
                      단, 반려견 장례비 청구 시 사망진단서를 첨부해 주세요.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container pet pb_0'>
          <div className='guide pb_56'>
            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>01</div>
              <div className='fs_20 fw_600 mt_20'>
                보상을 얼마를 받던 최대 5년동안
                <br />
                변하지 않는 보험료로 20세까지
                <br />
                <span className='col_orange'>길게 보장</span>해 드립니다!
              </div>
              <div className='fs_15 col_gray mt_12'>
                부담되는 반려동물 의료비
                <br />
                굿앤굿 우리펫보험으로 준비하세요
              </div>
              <div className='gng_gray_box mt_28'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>반려견 의료비</p>
                  <p className='fs_16 fw_600 col_gray3'>(슬/고관절 포함)</p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600'>일반형</p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>1일 보상한도 15만원</p>
                        <p className='col_gray'>(수술 시 200만원)</p>
                      </div>
                    </li>
                    <li className='align_start'>
                      <p className='fw_600'>고보장형</p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>1일 보상한도 30만원</p>
                        <p className='col_gray'>(수술 시 250만원)</p>
                      </div>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>50% / 70% / 80% / 90%</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>1천만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>자기부담금</p>
                      <p className='col_gray3'>1만원 / 3만원 / 5만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>보험기간</p>
                      <p className='col_gray3'>3년 / 5년</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>질병30일 / 슬,고관절 탈구 1년</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gng_gray_box mt_16'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>특정처치</p>
                  <p className='fs_16 fw_600 col_gray3'>(이물제거)</p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600'>내시경 이용시</p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>추가 200만원</p>
                        <p className=''>한도 내 보상</p>
                      </div>
                    </li>
                    <li className='align_start'>
                      <p className='fw_600'>구토유발 약물</p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>추가 20만원</p>
                        <p className=''>한도 내 실손보상</p>
                      </div>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>50% / 70% / 80% / 90%</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>200만원(연간 1회)</p>
                    </li>
                    <li>
                      <p className='fw_500'>자기부담금</p>
                      <p className='col_gray3'>1만원 / 3만원 / 5만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>보험기간</p>
                      <p className='col_gray3'>3년 / 5년</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>없음</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gng_gray_box mt_16'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>의료비 확장</p>
                  <p className='fs_16 fw_600 col_gray3'>(특정약물치료)</p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600 txt_left'>
                        아토피 및 피부질완 치료제
                        <br />
                        아포켈(경구용) 및 사이토
                        <br />
                        포인트(주사제)
                      </p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>1회당 10만원</p>
                        <p className=''>한도 보상</p>
                      </div>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>50% / 70% / 80% / 90%</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>60만원(최대 6회)</p>
                    </li>
                    <li>
                      <p className='fw_500'>자기부담금</p>
                      <p className='col_gray3'>1만원 / 3만원 / 5만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>보험기간</p>
                      <p className='col_gray3'>3년 / 5년</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>질병 90일</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gng_gray_box mt_16'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>의료비 확장</p>
                  <p className='fs_16 fw_600 col_gray3'>(MRI/CT)</p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600 txt_left'>
                        진단을 위한 MRI와
                        <br />
                        CT 검사비
                      </p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>최대 100만원</p>
                        <p className=''>한도</p>
                      </div>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>50% / 70% / 80% / 90%</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>100만원(연간 1회)</p>
                    </li>
                    <li>
                      <p className='fw_500'>자기부담금</p>
                      <p className='col_gray3'>1만원 / 3만원 / 5만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>보험기간</p>
                      <p className='col_gray3'>3년 / 5년</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>질병 30일</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gng_gray_box mt_16'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>배상책임</p>
                  <p className='fs_16 fw_600 col_gray3'></p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600 txt_left'>대인,대물(반려견 한정)</p>
                      <div className='txt_right'>
                        <p className='fw_600 col_darkblue'>1천만원</p>
                        <p className=''>한도 실손보상</p>
                      </div>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>100%</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>무한</p>
                    </li>
                    <li>
                      <p className='fw_500'>자기부담금</p>
                      <p className='col_gray3'>5만원 / 10만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>없음</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gng_gray_box mt_16'>
                <div className='dis_flex justify_between'>
                  <p className='fs_18 fw_600'>사망위로금</p>
                  <p className='fs_16 fw_600 col_gray3'></p>
                </div>
                <div className='in_white_box'>
                  <ul className='key_val_list gap8'>
                    <li className='align_start'>
                      <p className='fw_600 txt_left'>반려견이 사망한 경우</p>
                    </li>
                  </ul>
                  <ul className='key_val_list gap4 mt_16 pt_16 bortop_1'>
                    <li>
                      <p className='fw_500'>보상비율</p>
                      <p className='col_gray3'>정액</p>
                    </li>
                    <li>
                      <p className='fw_500'>연간보상한도</p>
                      <p className='col_gray3'>15만원</p>
                    </li>
                    <li>
                      <p className='fw_500'>면책기간</p>
                      <p className='col_gray3'>30일</p>
                    </li>
                  </ul>
                </div>
              </div>
              <p className='fs_12 col_gray mt_8'>
                주) 특정처치(내시경) 특정약물치료 MRI/CT비용은 중복보상하지 않음 (보상금액
                큰금액지급)
              </p>
            </div>
          </div>

          <div className='guide bg_gray pb_28'>
            <div className='txt_center pt_41'>
              <div className='orange_num_tag'>02</div>
              <div className='fs_20 fw_600 mt_20'>
                부담되는 <span className='col_orange'>의료비 적용</span> 항목
              </div>
              <div className='orange_chk_tag_list mt_28'>
                <p className='orange_chk_tag'>야간 진료비</p>
                <p className='orange_chk_tag'>통원 치료비</p>
                <p className='orange_chk_tag'>약값</p>
                <p className='orange_chk_tag'>진료비</p>
                <p className='orange_chk_tag'>검사비</p>
                <p className='orange_chk_tag'>주사비</p>
                <p className='orange_chk_tag'>치료비</p>
                <p className='orange_chk_tag'>입원</p>
                <p className='orange_chk_tag'>수술</p>
                <p className='orange_chk_tag'>응급실비</p>
              </div>
              <div className='inner_white_box type03 mt_32 gap8'>
                <p>
                  슬/고관절 탈구 <span className='col_orange fw_600'>의료비에서 보장</span>
                </p>
                <p>
                  이물제거 내시경 이용 <span className='col_orange fw_600'>비용 추가 200만원</span>
                </p>
                <p>
                  이물제거 구토유발약물 <span className='col_orange fw_600'>추가 20만원</span>
                </p>
                <p>
                  의료비확장 아포켈 및 사이토포인트{' '}
                  <span className='col_orange fw_600'>1회당 10만원</span>
                </p>
                <p>
                  MRI와 CT 검사비 <span className='col_orange fw_600'>최대 100만원</span>
                </p>
              </div>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>03</div>
              <div className='fs_20 fw_600 mt_20'>
                <span className='col_orange'>굿앤굿 우리펫보험</span>을<br />
                가입해야만 하는 이유
              </div>
              <div className='inner_white_box type08 mt_28'>
                <p className='fs_16 fw_600'>확장보장특약 가입 시</p>
                <p className='mt_4 fs_22 fw_600 col_orange'>슬관절, 고관절 질환도 보장!</p>
                <div className='dis_flex mt_20 gap8'>
                  <div className='gray_bor_box flex1'>
                    <p className='fs_13 fw_600 col_gray2'>특약 미가입 시</p>
                    <p className='fs_16 fw_700 mt_8'>반려견 의료비</p>
                    <p className='fs_15 fw_500 col_gray2'>기본계약, 슬/고관절</p>
                    <button className='btn gray mt_8'>
                      <span className='col_gray2'>면책</span>
                    </button>
                  </div>
                  <div className='orange_bor_box flex1'>
                    <p className='fs_13 fw_600 col_orange'>특약 가입 시</p>
                    <p className='fs_16 fw_700 mt_8'>반려견 의료비</p>
                    <p className='fs_15 fw_500 col_gray2'>기본계약, 슬/고관절</p>
                    <button className='btn orange mt_8'>보장가능</button>
                  </div>
                </div>
              </div>
              <div className='inner_white_box txt_left type08 mt_18'>
                <p className='fs_16 fw_500'>※ 유의사항</p>
                <p className='fs_15 fw_600 mt_12 col_gray3'>
                  - 기본계약과 합산하여 보험증권에 기재된 보상 한도 내에서 지급합니다.
                </p>
                <p className='fs_15 fw_600 mt_8 col_gray3'>
                  - 어떠한 경우에도 보상한도를 특별약관별로 각각 적용하지 않습니다.
                </p>
                <div className='gng_gray_box mt_20'>
                  <p className='fw_600'>[예시]</p>
                  <p className='fw_600 col_gray mt_8'>
                    1일 진료비: 외이도염 6만원, 슬관절 80만원 (비수술) 고보장형 (보상비율 70%,
                    자기부담금 3만원)
                  </p>
                  <p className='fw_600 mt_8 col_orange'>확장보장특약가입!!</p>
                  <p className='fw_600 col_gray mt_8'>{`보험금 = [{(6만원 + 80만원) - 3만원} x 70%, 30만원 한도] = 30만원`}</p>
                </div>
              </div>

              <div className='gng_cus_box mt_18'>
                <div className='tit_wrap'>
                  <span className='col_orange'>반려견 의료비에서 보장하는 금액</span>의<br />
                  초과분 최대 200만원(내시경) 보장!
                </div>
                <div className='white_wrap'>
                  <div className='gray_tag_tit'>확장보장특약 미가입 시</div>
                  <p className='mt_8 fs_24 fw_600'>1일당 한도 선택 가입</p>
                  <div className='dis_flex gap16 mt_20'>
                    <div className='flex1 gray_bor_box'>
                      <p className='fs_20 fw_600'>15만원</p>
                      <p className='fs_18 col_gray3'>(일반형)</p>
                    </div>
                    <div className='flex1 gray_bor_box'>
                      <p className='fs_20 fw_600'>30만원</p>
                      <p className='fs_18 col_gray3'>(고보장형)</p>
                    </div>
                  </div>
                </div>
                <div className='orange_wrap'>
                  <div className='orange_tag_tit'>확장보장특약 가입 시</div>
                  <p className='fs_24 mt_8 fw_600'>내시경 이용</p>
                  <p className='fs_18 mt_8 fw_600 col_orange'>추가 200만원 한도 내 보상</p>
                </div>
                <div className='orange_wrap'>
                  <div className='orange_tag_tit'>확장보장특약 가입 시</div>
                  <p className='fs_24 mt_8 fw_600'>구토유발 약물</p>
                  <p className='fs_18 mt_8 fw_600 col_orange'>추가 20만원 한도 내 보상</p>
                  <p className='fs_16 fw_600 col_gray'>(내시경 이용 가입금액의 10% 적용)</p>
                </div>
                <div className='orange_wrap'>
                  <div className='orange_tag_tit'>확장보장특약 가입 시</div>
                  <p className='fs_24 mt_8 fw_600'>
                    이물질 제거 시<br />
                    최대 230만원 한도 보상
                  </p>
                  <p className='fs_16 mt_8 fw_600 col_gray'>
                    내시경 제거 적용/동일날짜 내시경+ 구도유발 약물 동시처치 시 이물제거(내시경)
                    보험금만 지급
                  </p>
                </div>
              </div>
              <div className='gng_cus_box mt_18'>
                <div className='tit_wrap'>
                  <span className='col_orange'>아포퀠/사이토포인트는 물론</span>
                  <br />
                  경구용 항암제까지 업계 유일 추가보장!
                </div>
                <div className='white_wrap'>
                  <div className='gray_tag_tit'>확장보장특약 미가입 시</div>
                  <p className='mt_8 fs_24 fw_600'>피부질환 치료제</p>
                  <p className='mt_8 fs_16 fw_600 col_gray3'>강아지 아토피피부염 등 치료제 면책</p>
                  <div className='gray_bg_box txt_left mt_24'>
                    <p className='fw_600 col_blue'>아포퀠</p>
                    <p className='fw_600 mt_8'>가려움 유발 요소에 대응하는 면역세포를 활성화</p>
                    <div className='fw_600 mt_8 col_gray'>- 경구용 약제</div>
                    <div className='fw_600 mt_4 col_gray'>- 반려견 만 1세이상 부터 사용 가능</div>
                    <div className='fw_600 mt_4 col_gray'>
                      - 아토피 치료 외 기타 피부질환에도 사용
                    </div>
                    <div className='fw_600 mt_4 col_gray'>
                      - 100정에 약 30만원(일일 약 1정~1.5정) (1알 기준 약 5천원)
                    </div>
                    <p className='fw_600 col_blue mt_20'>사이토 포인트</p>
                    <p className='fw_600 mt_8'>일종의 표적치료제로 가려움 유발 인자에 직접 작용</p>
                    <div className='fw_600 mt_8 col_gray'>- 주사제(체중별 투여량 상이)</div>
                    <div className='fw_600 mt_4 col_gray'>- 반려견 만 3개월 이상부터 사용 가능</div>
                    <div className='fw_600 mt_4 col_gray'>- 1회 투여 시 13~15만원</div>
                  </div>
                </div>
                <div className='orange_wrap'>
                  <div className='orange_tag_tit'>확장보장특약 가입 시</div>
                  <p className='fs_24 mt_8 fw_600'>치료제 및 경구용 항암제</p>
                  <p className='fs_16 mt_8 fw_600 col_gray'>
                    1회 10만원 한도
                    <br />
                    연간 최대 6회(60만원) 보장
                  </p>
                </div>
              </div>
              <div className='gng_cus_box mt_18'>
                <div className='tit_wrap'>
                  우리 애기 습관적인 탈구
                  <br />
                  <span className='col_orange'>
                    반드시 동반되는 검사비!
                    <br />
                    업계최대 100만원 보장!
                  </span>
                </div>
                <div className='white_wrap'>
                  <div>
                    <img src='/image/banner/gng_b_img_01.png' alt='' />
                  </div>
                  <div className='mt_16'>
                    <img src='/image/banner/gng_b_img_02.png' alt='' />
                  </div>
                  <div className='mt_16'>
                    <img src='/image/banner/gng_b_img_03.png' alt='' />
                  </div>
                  <div className='mt_24 fs_20 fw_600'>
                    습관적 탈구, 치아치료,
                    <br />
                    이물질 걸림 등 정확한 진단을
                    <br />
                    위한 MRI/CT 검사
                  </div>
                </div>
                <div className='orange_wrap'>
                  <div className='orange_tag_tit'>확장보장특약 가입 시</div>
                  <p className='fs_24 mt_8 fw_600'>
                    우리 아이 치료비 외<br />
                    검사비까지!
                  </p>
                  <p className='fs_16 mt_8 fw_600 col_gray'>
                    정확한 진단을 위한 MRI/CT
                    <br />
                    최대 100만원
                  </p>
                </div>
              </div>
            </div>

            <div className='txt_center mt_56'>
              <div className='orange_num_tag'>04</div>
              <div className='fs_20 fw_600 mt_20'>가입 및 보상절차</div>
              <div className='mt_12 fs_16 fw_600'>
                충분하게 치료부터 하신 후 현대해상 보상팀으로
                <br />
                <span className='col_orange'>팩스나 이메일 또는 카톡이나 문자</span>로<br />
                보내주시면 됩니다
              </div>
              <div className='mt_36'>
                <div className='inner_white_box type05'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_01.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      병원에서 발행하는 진료내역서 및 영수증
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_02.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>보험금 청구서 양식 작성 및 동의서</p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_03.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      피보험자 통장 사본 및<br />
                      본인 확인을 위한 신분증 사본
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type05 mt_12'>
                  <div className='dis_flex justify_between gap16'>
                    <img src='/image/banner/pet_b_img_04.png' alt='' className='w_48' />
                    <p className='fs_14 fw_600 flex1 txt_left'>
                      보험 가입 반려견 사진
                      <br />
                      (전면 사진, 측면 사진, 후면 사진)
                    </p>
                  </div>
                </div>
                <div className='inner_white_box type06 mt_12'>
                  <div className='dis_flex gap16'>
                    <img src='/image/icon/printer_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>팩스 : 0507-774-6060</p>
                  </div>
                  <div className='dis_flex gap16 mt_8'>
                    <img src='/image/icon/mail_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>이메일 : hibs@hi.co.kr</p>
                  </div>
                  <div className='dis_flex gap16 mt_8'>
                    <img src='/image/icon/phone_orange_icon.png' alt='' className='w_20' />
                    <p className='fs_14 fw_600'>연락처 : 1588-5656</p>
                  </div>
                </div>
                <div className='inner_white_box type07 mt_12'>
                  <div className='dis_flex column gap8'>
                    <img src='/image/icon/horn_orange_icon.png' alt='' className='w_36' />
                    <div className='fs_14 col_gray3'>
                      보험금 청구를 해보신 적이 있으셨다면{' '}
                      <span className='col_black fw_600'>
                        영수증, 내역서, 보험금 청구서 양식 3가지 서류만
                      </span>{' '}
                      팩스나 이메일 또는 카톡이나 문자로 접수하시면 됩니다
                    </div>
                    <div className='col_orange fs_13 fw_600 underline'>
                      단, 반려견 장례비 청구 시 사망진단서를 첨부해 주세요
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <!-- // container --> */}

      {/* <!-- foot_container --> */}
      <div className='foot_container'>
        <div className='foot_btn'>
          <button className='btn orange' onClick={handleDownload}>
            보험금 청구서 다운받기
          </button>
        </div>
      </div>
      {/* <!-- // foot_container --> */}
    </div>
  );
}

export default PetDetail;
