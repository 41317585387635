/* -------------------------------------------------------------------------- */
/*                                  보험금 청구 내역                                 */
/* -------------------------------------------------------------------------- */
// 사고유형
export const getAccidentType = (type: number | undefined) => {
  if (type === undefined) return '-';

  if (type === 1) return '상해';
  else if (type === 0) return '질병';
  else return '-';
};

// 손해유형
export const getDamageType = (type: number | undefined) => {
  if (type === undefined) return '-';

  if (type === 1) return '재물';
  else if (type === 0) return '배상책임';
  else return '-';
};

// 진행상태
// RECEIPT : 접수, CANCEL : 취소, SUPPLEMENT : 보완, WRITING : 작성중, ADD : 추가접수, COMPLETE : 완료
export const getProgress = (value: string) => {
  if (value === 'RECEIPT') return '접수';
  else if (value === 'CANCEL') return '취소';
  else if (value === 'SUPPLEMENT') return '보완';
  else if (value === 'WRITING') return '작성중';
  else if (value === 'ADD') return '추가접수';
  else if (value === 'COMPLETE') return '완료';
  else return '-';
};

// 진행상태 css
export const getProgressStyle = (value: string) => {
  if (value === 'RECEIPT') return 'col_green';
  else if (value === 'CANCEL') return 'col_red';
  else if (value === 'SUPPLEMENT') return 'col_orange';
  else if (value === 'WRITING') return 'col_gray';
  else if (value === 'ADD') return 'col_blue';
  else if (value === 'COMPLETE') return 'col_gray2';
  else return '';
};
