interface Config {
  refreshToken: string;
  identifyVerification: string;
  login: string;
  findId: string;
  findPw: string;
  changePw: string;
  joinTargetCheck: string;
  duplicateCheck: string;
  signup: string;
  alarmHistory: string;
  alarmConfig: string;
  notice: string;
  faq: string;
  userInfo: string;
  guaranteHistory: string;
  question: string;
  insuranceClaimHistory: string;
  s3: string;
  addInsuranceClaimAttachedDocument: string;
  withdrawal: string;
  petRegister: string;
  groupAccidentInsuranceClaim: string;
  createAttachedDocument: string;
  guaranteInfo: string;
  overseasTravel: string;
  longStayContinent: string;
  submitLongStay: string;
  submitOverseasTravel: string;
  submitDomesticTravel: string;
  insuranceFeeOverseasTravel: string;
  accessCodeCheck: string;
  insuranceCompany: string;
}

/* -------------------------------------------------------------------------- */
/*                                   config                                   */
/* -------------------------------------------------------------------------- */
const endpointConfig: Config = {
  // s3 주소
  s3: process.env.REACT_APP_S3_URL || '',
  // 리프레쉬 토큰 엔드포인트
  refreshToken: '/auth/refresh',
  // 본인 인증
  identifyVerification: '/auth/identity-verification',
  // 로그인
  login: '/auth/login',
  // 아이디 찾기
  findId: '/auth/find-id',
  // 비밀번호 찾기
  findPw: '/auth/find-password',
  // 비밀번호 변경
  changePw: '/auth/password-change',
  // 가입대상 확인
  joinTargetCheck: '/auth/join-target-check',
  // 아이디 중복체크
  duplicateCheck: '/auth/duplicate-check',
  // 회원가입
  signup: '/auth/join',
  // 알림 히스토리 조회
  alarmHistory: '/users/alarm-history',
  // 알림 설정 조회 & 수정
  alarmConfig: '/users/alarm-config',
  // 공지사항 조회
  notice: '/users/notice',
  // FAQ 리스트 호출
  faq: '/users/faq',
  // 정보 조회 & 수정
  userInfo: '/users/info',
  // 보장내역 조회
  guaranteHistory: '/users/guarante-history',
  // 문의내역
  question: '/users/question',
  // 마이페이지 > 보험금 청구
  insuranceClaimHistory: '/users/insurance-claim-history',
  // 보험청구 첨부서류 추가등록
  addInsuranceClaimAttachedDocument: '/users/insurance-claim/add-attached-document',
  // 회원탈퇴
  withdrawal: '/auth/withdrawal',
  // 펫보험 상담신청
  petRegister: '/users/insurance-claim-pet',
  // 단체상해보험 청구
  groupAccidentInsuranceClaim: '/users/insurance-claim-group-accident',
  // 첨부서류 등록
  createAttachedDocument: '/users/insurance-claim/attached-document',
  // 보장안내 조회(장기체류, 해외, 국내)
  guaranteInfo: '/users/guarante-info',
  // 해외여행보험 조회
  overseasTravel: '/common/country-list/overseas-travel',
  // 장기체류보험 대륙 조회
  longStayContinent: '/common/country-list/long-stay',
  // 장기체류보험 청구
  submitLongStay: '/users/insurance-claim-long-stay',
  // 해외여행보험 청구
  submitOverseasTravel: '/users/insurance-claim-overseas-travel',
  // 국내여행보험 청구
  submitDomesticTravel: '/users/insurance-claim-domestic-travel',
  // 해외여행보험 보험료 조회
  insuranceFeeOverseasTravel: '/common/insurance-fee',
  //기업 접근코드 체크
  accessCodeCheck: '/users/access-code-check',

  insuranceCompany: '/users/insurance-claim-enterprise',
};

export default endpointConfig;
