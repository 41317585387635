import React, { useState } from 'react';
import useNavigateTo from '../../hooks/useNavigateTo';
import { COMPANY_INSURANCE_CODE, HOME } from '../../utils/routers';
import useCompanyInsuranceFunnel from '../../hooks/useCompanyInsuranceFunnel';

interface Props {
  path?: string;
  title: string;
  hasCloseBtn?: boolean;
  hasHomeBtn?: boolean;
  hasTolltip?: boolean;
  cancel?: boolean;
  tooltipContent?: string | React.ReactNode;
  link?: { title: string; path: string } | undefined;
  closeFunc?: () => void;
  tab?: React.ReactNode;
  terms?: boolean;
}
function DetailHeader({
  path,
  title,
  hasCloseBtn = false,
  hasHomeBtn = false,
  hasTolltip = false,
  cancel = false,
  terms = false,
  tooltipContent,
  link,
  closeFunc,
  tab,
}: Props) {
  const { goTo, goBack } = useNavigateTo();
  const [isVisible, setIsVisible] = useState(false);
  const { clearForm } = useCompanyInsuranceFunnel();

  React.useEffect(() => {
    const handleScroll = () => {
      if (isVisible) {
        console.log('scroll');
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  const [cancelModalDesc, setCancelModalDesc] = useState(false);
  const onCloseSuccessModal = () => {
    clearForm();
    goTo(HOME);
    setCancelModalDesc(false);
  };

  return (
    <div className='header'>
      <div className='guide'>
        {!hasCloseBtn ? (
          <div>
            <button
              className='l_arrow_icon'
              onClick={path ? () => goTo(path) : () => goBack()}
            ></button>
          </div>
        ) : (
          <div></div>
        )}
        <div className='tit' style={terms ? { letterSpacing: '-2.3px' } : {}}>{title}</div>
        {hasCloseBtn ? (
          <div>
            <button className='x_icon' onClick={closeFunc ? closeFunc : () => goBack()}></button>
          </div>
        ) : hasHomeBtn ? (
          <div>
            <button className='home_icon' onClick={() => goTo(HOME)}></button>
          </div>
        ) : hasTolltip ? (
          <div
            className='tooltip_container'
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <button className='q_icon'></button>
            {isVisible && <div className='tooltip'>{tooltipContent}</div>}
          </div>
        ) : link ? (
          <div>
            <button
              onClick={() => goTo(link.path)}
              style={{
                fontSize: '14px',
                color: '#505050',
                fontWeight: '500',
                borderBottom: '1px solid #505050',
                paddingBottom: '1px',
              }}
            >
              {link.title}
            </button>
          </div>
        ) : cancel ? (
            <div>
              <button onClick={() => setCancelModalDesc(true)}>작성 취소</button>
            </div>
          ) :
          <div></div>
        }
      </div>
      {tab && tab}

      {/* <!-- alert_pop --> */}
      <CancelModal
        onClose={onCloseSuccessModal}
        sx={{ display: cancelModalDesc ? 'block' : 'none' }}
      />
    </div>
  );
}

export default DetailHeader;

interface SuccessModalProps {
  onClose: () => void;
  sx: any;
}

const CancelModal = ({ onClose, sx }: SuccessModalProps) => {
  return (
    <div className='alert_pop' id='alert02' style={sx}>
      <div className='pop_wrap'>
        <div className='pop_tit'>작성을 취소하시겠습니까?</div>
        <div className='pop_body'>
          <div className='msg'>작성을 취소하시면 처음부터 다시{'\n'}작성하셔야 합니다.</div>
        </div>
        <div className='pop_btn'>
          <button className='btn black' onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
