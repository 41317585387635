import { Link } from 'react-router-dom';
import {
  CREDIT_COLLECTION,
  CREDIT_PROVISION,
  CREDIT_INQUIRY,
} from '../utils/routers';

interface TermsProps {
  checkedList: string[];
  handleCheck: (key: string) => void;
  handleAllCheck: () => void;
}
const CreditTerms = ({ checkedList, handleCheck, handleAllCheck }: TermsProps) => {
  return (
    <>
      <div className='checkbox mt_20'>
        <input
          id='chkAll'
          type='checkbox'
          checked={checkedList.length === TERMS_LIST.length}
          onChange={handleAllCheck}
        />
        <label htmlFor='chkAll' className='fs_16 fw_600'>
          전체 선택
        </label>
      </div>
      <ul className='checkbox_list mt_12'>
        {TERMS_LIST.map(term => (
          <li key={term.id}>
            <div className='checkbox'>
              <input
                id={term.id}
                type='checkbox'
                checked={checkedList.includes(term.key)}
                onChange={() => handleCheck(term.key)}
              />
              <label htmlFor={term.id}></label>
            </div>
            <Link to={term.path} className='a_link'>
              <div>
                {term.title}
              </div>
              <i className='r_arrow_icon'></i>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CreditTerms;

export const TERMS_LIST = [
  {
    id: 'chk01',
    key: 'collection',
    path: CREDIT_COLLECTION,
    title: '개인(신용)정보 수집, 이용 동의',
  },
  {
    id: 'chk02',
    key: 'provision',
    path: CREDIT_PROVISION,
    title: '개인(신용)정보 제공에 관한 동의',
  },
  {
    id: 'chk03',
    key: 'inquiry',
    path: CREDIT_INQUIRY,
    title: '개인(신용)정보 조회에 관한 동의',
  },
];
